import { View } from "react-native";
import { Container } from "../container";
import { Markdown } from "./markdown";
import { MessageTemp } from "./types";
import { MessageDoc } from "@/server/db/messages";
import { Text } from "../text";
import { colors } from "../colors";

export interface AssistantMessageProps {
  message: MessageDoc & { role: "assistant" };
}

export function AssistantMessage(props: AssistantMessageProps) {
  const { message } = props;

  if (message.error) {
    return (
      <Container>
        <View style={{ flex: 1 }}>
          <Text color={colors.text.destructive}>
            Lỗi xảy ra khi xử lý tin nhắn. Vui lòng thử lại sau.
          </Text>
        </View>
      </Container>
    );
  }

  return (
    <Container>
      <View style={{ flex: 1 }}>
        <Markdown>{message.text}</Markdown>
      </View>
    </Container>
  );
}

export function isAssistantMessage(
  message: MessageDoc | MessageTemp,
): message is MessageDoc & { role: "assistant" } {
  return message.role === "assistant";
}
