.benefitWrapper {
  padding-top: 50px;
  padding-bottom: 50px;

  display: flex;
  flex-direction: row;
  gap: 40px;
}

.benefitImage {
  width: 104px;
  height: 104px;
  display: flex;
}

.benefit {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.benefitInner {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .benefitWrapper {
    flex-direction: column;
  }

  .benefit {
    align-items: flex-start;
  }
}
