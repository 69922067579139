import { getLocales, getCalendars } from "expo-localization";
import { I18n } from "i18n-js";

export const locales = getLocales();
export const calendars = getCalendars();

export const i18n = new I18n({
  en: {
    app: {
      previous: "Previous",
      next: "Next",
      close: "Close",
    },
    chat: {
      attachFile: "Attach file",
      removeFile: "Remove file",
      scrollToBottom: "Scroll to bottom",
      send: "Send message",
      inputPlaceholder: "Type a message",
      disclaimer: "Information may be be inaccurate or outdated",
    },
  },
  vi: {
    app: {
      previous: "Trước",
      next: "Tiếp theo",
      close: "Đóng",
    },
    chat: {
      attachFile: "Đính kèm tệp",
      removeFile: "Xóa tệp",
      scrollToBottom: "Cuộn xuống dưới",
      send: "Gửi tin nhắn",
      inputPlaceholder: "Nhập tin nhắn",
      disclaimer: "Thông tin có thể không chính xác",
    },
  },
});

i18n.locale = "vi";
i18n.enableFallback = true;
