import { Link } from "expo-router";
import { StyleSheet, View } from "react-native";
import { Text } from "../text";
import { colors } from "../colors";

interface HeaderProps {}

export function Header(props: HeaderProps) {
  return (
    <View style={styles.container}>
      <Link href="/admin/articles">
        <Text>Articles</Text>
      </Link>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 80,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: colors.border.default,
    backgroundColor: colors.background.default,
  },
});
