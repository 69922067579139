import { View } from "react-native";

import { ButtonFlat } from "../button_flat";
import { useDeviceId } from "@/helpers/device";
import { ThreadDoc } from "@/server/db/threads";
import { useQuery } from "@/helpers/api";
import { api } from "@/server/api";

interface ThreadListProps {
  onPress: (thread: ThreadDoc) => void;
}

export function ThreadList(props: ThreadListProps) {
  const { onPress } = props;
  const deviceId = useDeviceId();
  const threads = useQuery(
    api.threads.getAll,
    deviceId ? { deviceId } : "skip",
  );

  if (!threads) {
    return null;
  }

  return (
    <View>
      {threads.map((thread) => (
        <ButtonFlat
          key={thread.id}
          title={thread.title!}
          onPress={() => onPress(thread)}
        />
      ))}
    </View>
  );
}
