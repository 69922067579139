import { Pressable, StyleSheet, View } from "react-native";
import { FileImage, FileTemp } from "./types";
import { useState } from "react";
import { ImageGalleryModal } from "../image_gallery_modal";
import { colors } from "../colors";
import { Image } from "expo-image";
import { Text } from "../text";
import { FileDoc } from "@/server/db/messages";

export interface MessageFilesProps {
  files: (FileTemp | FileDoc)[];
}

export function MessageFiles(props: MessageFilesProps) {
  const { files } = props;

  const [imageGallery, setImageGallery] = useState({
    visible: false,
    initialIndex: 0,
  });
  const images = files.filter((file) => file.type === "image") as FileImage[];

  return (
    <>
      {imageGallery.visible && (
        <ImageGalleryModal
          initialIndex={imageGallery.initialIndex}
          images={images}
          visible
          onRequestClose={() =>
            setImageGallery({ visible: false, initialIndex: 0 })
          }
        />
      )}
      <View style={styles.imagesWrapper}>
        {/* At max show 4 images with option to view more in modal. Beyond 4 we consider them "overflowing" */}
        {images.slice(0, 4).map((image, index) => {
          const lastItemInimageOverflow = images.length > 4 && index === 3;

          return (
            <View key={image.name} style={styles.file}>
              <Pressable
                onPress={() => {
                  setImageGallery({ visible: true, initialIndex: index });
                }}
              >
                {({ hovered }) => (
                  <>
                    <Image style={styles.file} source={{ uri: image.url }} />
                    {(hovered || lastItemInimageOverflow) && (
                      <View
                        style={[
                          styles.fileBackdrop,
                          {
                            opacity:
                              hovered && lastItemInimageOverflow ? 0.95 : 0.8,
                          },
                        ]}
                      >
                        {index === 3 && (
                          <Text size="xl" color={colors.text.inverted}>
                            {images.length > 4 ? `+${images.length - 4}` : ""}
                          </Text>
                        )}
                      </View>
                    )}
                  </>
                )}
              </Pressable>
            </View>
          );
        })}
      </View>
      {/* TODO: Support files with other extensions below */}
    </>
  );
}

const styles = StyleSheet.create({
  fileBackdrop: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 8,
    backgroundColor: colors.background.backdrop,
    justifyContent: "center",
    alignItems: "center",
  },
  imagesWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    gap: 16,
  },
  file: {
    width: 128,
    height: 128,
    borderRadius: 8,
  },
});
