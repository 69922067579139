import React, { useRef, useEffect, useCallback } from "react";
import { Platform, StyleSheet, View } from "react-native";

type PopoverPosition = "topLeft" | "topRight" | "bottomLeft" | "bottomRight";

interface PopoverProps {
  visible: boolean;
  content: React.ReactNode;
  children: React.ReactNode;
  onRequestClose?: () => void;
  position?: PopoverPosition;
  offset?: number;
}

export function Popover(props: PopoverProps) {
  const {
    visible,
    content,
    children,
    position = "bottomLeft",
    offset = 8,
    onRequestClose,
  } = props;

  const popoverRef = useRef<View>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!visible) {
        return;
      }

      if (Platform.OS === "web") {
        const node = popoverRef.current as unknown as HTMLElement;

        if (node && !node.contains(event.target as Node)) {
          onRequestClose?.();
        }
      }
    },
    [onRequestClose, visible, popoverRef],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && visible) {
        onRequestClose?.();
      }
    },
    [onRequestClose, visible],
  );

  useEffect(() => {
    if (Platform.OS === "web") {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClickOutside, handleKeyDown]);

  return (
    <View>
      {children}
      <View
        style={[
          styles.popover,
          // we want to maintain popover state if there's one
          visible && styles.visible,
          styles[position],
          (position === "bottomLeft" || position === "bottomRight") && {
            marginTop: offset,
          },
          (position === "topLeft" || position === "topRight") && {
            marginBottom: offset,
          },
        ]}
        ref={popoverRef}
      >
        {content}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  popover: {
    position: "absolute",
    display: "none",
  },
  visible: {
    display: "flex",
  },

  topLeft: {
    bottom: "100%",
    left: 0,
  },
  topRight: {
    bottom: "100%",
    right: 0,
  },
  bottomLeft: {
    top: "100%",
    left: 0,
  },
  bottomRight: {
    top: "100%",
    right: 0,
  },
});
