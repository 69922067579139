import styles from "./heading.module.css";

interface HeadingProps {
  children: string;
}

export function Heading(props: HeadingProps) {
  const { children } = props;

  return <div className={styles.heading}>{children}</div>;
}
