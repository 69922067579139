import React, { useCallback, useReducer } from "react";
import { StyleSheet, View, LayoutChangeEvent } from "react-native";

interface AutoSizerProps {
  autoSizeWidthOnly?: boolean;
  autoSizeHeightOnly?: boolean;
  onResize?: (size: AutoSizerChildProps) => void;
  /** children are not rendered until widths and heights is received */
  children: React.ReactNode | ((size: AutoSizerChildProps) => React.ReactNode);
}

interface State {
  ready: boolean;
  height: number;
  width: number;
}

export interface AutoSizerChildProps {
  width: number;
  height: number;
}

type Action =
  | { type: "INITIALIZE"; width: number; height: number }
  | { type: "RESIZE"; width: number; height: number }
  | { type: "RESIZE_WIDTH"; width: number }
  | { type: "RESIZE_HEIGHT"; height: number };

function reducer(prevState: State, action: Action): State {
  switch (action.type) {
    case "INITIALIZE":
      return {
        ...prevState,
        ready: true,
        width: action.width,
        height: action.height,
      };
    case "RESIZE":
      return {
        ...prevState,
        width: action.width,
        height: action.height,
      };
    case "RESIZE_WIDTH":
      return {
        ...prevState,
        width: action.width,
      };
    case "RESIZE_HEIGHT":
      return {
        ...prevState,
        height: action.height,
      };
    default:
      throw new Error("Invalid action type");
  }
}

const initialState: State = {
  ready: false,
  height: 0,
  width: 0,
};

export function AutoSizer(props: AutoSizerProps): JSX.Element {
  const {
    children,
    onResize,
    autoSizeWidthOnly = false,
    autoSizeHeightOnly = false,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { ready, width, height } = state;

  const resizeBoth = !autoSizeWidthOnly && !autoSizeHeightOnly;

  const handleLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const newWidth = event.nativeEvent.layout.width;
      const newHeight = event.nativeEvent.layout.height;

      if (!ready) {
        dispatch({
          type: "INITIALIZE",
          width: newWidth,
          height: newHeight,
        });
        onResize?.({ width: newWidth, height: newHeight });
        return;
      }

      if (resizeBoth) {
        dispatch({
          type: "RESIZE",
          width: newWidth,
          height: newHeight,
        });
      } else if (autoSizeWidthOnly) {
        dispatch({
          type: "RESIZE_WIDTH",
          width: newWidth,
        });
      } else if (autoSizeHeightOnly) {
        dispatch({
          type: "RESIZE_HEIGHT",
          height: newHeight,
        });
      }

      onResize?.({ width: newWidth, height: newHeight });
    },
    [ready, onResize, autoSizeWidthOnly, autoSizeHeightOnly, resizeBoth],
  );

  return (
    <View
      onLayout={handleLayout}
      style={[
        resizeBoth && styles.widthAndHeight,
        autoSizeWidthOnly && styles.widthOnly,
        autoSizeHeightOnly && styles.heightOnly,
      ]}
    >
      {ready
        ? typeof children === "function"
          ? children({ width, height })
          : children
        : null}
    </View>
  );
}

const styles = StyleSheet.create({
  widthAndHeight: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  widthOnly: {
    width: "100%",
  },
  heightOnly: {
    flex: 1,
    height: "100%",
  },
});
