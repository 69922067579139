import { StyleSheet, View } from "react-native";
import { Container } from "../container";
import { Text } from "../text";
import { colors } from "../colors";
import { MessageTemp } from "./types";
import { MessageFiles } from "./message_files";
import { MessageDoc } from "@/server/db/messages";

export interface UserMessageProps {
  message: (MessageDoc | MessageTemp) & { role: "user" };
}

export function UserMessage(props: UserMessageProps) {
  const { message } = props;

  return (
    <Container>
      <View style={{ alignItems: "flex-end" }}>
        {message.files.length > 0 && (
          <>
            <MessageFiles files={message.files} />
            <View style={{ height: 16 }} />
          </>
        )}
        <View style={styles.container}>
          <View style={styles.textContainer}>
            <Text>{message.text}</Text>
          </View>
        </View>
      </View>
    </Container>
  );
}

export function isUserMessage(
  message: MessageDoc | MessageTemp,
): message is (MessageDoc | MessageTemp) & { role: "user" } {
  return message.role === "user";
}

const styles = StyleSheet.create({
  container: {
    maxWidth: "80%",
  },
  textContainer: {
    padding: 16,
    borderRadius: 16,
    backgroundColor: colors.background.mutedExtra,
  },
  fileContainer: {},
  file: {},
  image: {},
});
