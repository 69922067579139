// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./_layout.tsx": { enumerable: true, get() { return require("/github/workspace/app/_layout.tsx"); } },
  "./admin/_layout.tsx": { enumerable: true, get() { return require("/github/workspace/app/admin/_layout.tsx"); } },
  "./admin/articles/[articleId].tsx": { enumerable: true, get() { return require("/github/workspace/app/admin/articles/[articleId].tsx"); } },
  "./admin/articles/_layout.tsx": { enumerable: true, get() { return require("/github/workspace/app/admin/articles/_layout.tsx"); } },
  "./admin/articles/index.tsx": { enumerable: true, get() { return require("/github/workspace/app/admin/articles/index.tsx"); } },
  "./admin/articles/new.tsx": { enumerable: true, get() { return require("/github/workspace/app/admin/articles/new.tsx"); } },
  "./admin/index.tsx": { enumerable: true, get() { return require("/github/workspace/app/admin/index.tsx"); } },
  "./c/[threadId].tsx": { enumerable: true, get() { return require("/github/workspace/app/c/[threadId].tsx"); } },
  "./c/_layout.tsx": { enumerable: true, get() { return require("/github/workspace/app/c/_layout.tsx"); } },
  "./c/index.tsx": { enumerable: true, get() { return require("/github/workspace/app/c/index.tsx"); } },
  "./console.tsx": { enumerable: true, get() { return require("/github/workspace/app/console.tsx"); } },
  "./help/_layout.tsx": { enumerable: true, get() { return require("/github/workspace/app/help/_layout.tsx"); } },
  "./help/index.tsx": { enumerable: true, get() { return require("/github/workspace/app/help/index.tsx"); } },
  "./help/privacy.tsx": { enumerable: true, get() { return require("/github/workspace/app/help/privacy.tsx"); } },
  "./help/terms.tsx": { enumerable: true, get() { return require("/github/workspace/app/help/terms.tsx"); } },
  "./index.tsx": { enumerable: true, get() { return require("/github/workspace/app/index.tsx"); } },
  "./index.web.tsx": { enumerable: true, get() { return require("/github/workspace/app/index.web.tsx"); } },
  "./login.tsx": { enumerable: true, get() { return require("/github/workspace/app/login.tsx"); } },
  "./suc-khoe/[slug].tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/[slug].tsx"); } },
  "./suc-khoe/_layout.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/_layout.tsx"); } },
  "./suc-khoe/benh-dau-vai-gay-va-cach-chua-tri.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/benh-dau-vai-gay-va-cach-chua-tri.tsx"); } },
  "./suc-khoe/cach-chua-dau-lung-thoat-vi-dia-dem-giai-phap-va-phuong-phap-hieu-qua.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/cach-chua-dau-lung-thoat-vi-dia-dem-giai-phap-va-phuong-phap-hieu-qua.tsx"); } },
  "./suc-khoe/cach-chua-tri-dau-vai-gay-huong-dan-toan-dien-va-chi-tiet.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/cach-chua-tri-dau-vai-gay-huong-dan-toan-dien-va-chi-tiet.tsx"); } },
  "./suc-khoe/cach-tri-dau-dau-giai-phap-va-nhung-hieu-biet-sau-ve-y-hoc.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/cach-tri-dau-dau-giai-phap-va-nhung-hieu-biet-sau-ve-y-hoc.tsx"); } },
  "./suc-khoe/cach-tri-dau-nhuc-xuong-khop-tai-nha-mot-cach-hieu-qua.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/cach-tri-dau-nhuc-xuong-khop-tai-nha-mot-cach-hieu-qua.tsx"); } },
  "./suc-khoe/dau-bung-ben-phai-la-gi-nguyen-nhan-trieu-chung-va-cach-dieu-tri.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/dau-bung-ben-phai-la-gi-nguyen-nhan-trieu-chung-va-cach-dieu-tri.tsx"); } },
  "./suc-khoe/dau-bung-ben-phai-nguyen-nhan-trieu-chung-va-cach-dieu-tri.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/dau-bung-ben-phai-nguyen-nhan-trieu-chung-va-cach-dieu-tri.tsx"); } },
  "./suc-khoe/dau-dau-nguyen-nhan-trieu-chung-va-cach-dieu-tri.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/dau-dau-nguyen-nhan-trieu-chung-va-cach-dieu-tri.tsx"); } },
  "./suc-khoe/dau-hieu-benh-tim-nhan-biet-va-phong-ngua.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/dau-hieu-benh-tim-nhan-biet-va-phong-ngua.tsx"); } },
  "./suc-khoe/dau-hieu-canh-bao-ung-thu-hieu-biet-de-phong-ngua.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/dau-hieu-canh-bao-ung-thu-hieu-biet-de-phong-ngua.tsx"); } },
  "./suc-khoe/dau-hieu-cua-benh-tieu-duong-nhan-biet-va-hieu-ro.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/dau-hieu-cua-benh-tieu-duong-nhan-biet-va-hieu-ro.tsx"); } },
  "./suc-khoe/dau-hieu-cua-suy-than.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/dau-hieu-cua-suy-than.tsx"); } },
  "./suc-khoe/dau-lung-nguyen-nhan-trieu-chung-va-giai-phap.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/dau-lung-nguyen-nhan-trieu-chung-va-giai-phap.tsx"); } },
  "./suc-khoe/dau-nguc-tim-hieu-chi-tiet-ve-nguyen-nhan-va-cach-dieu-tri.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/dau-nguc-tim-hieu-chi-tiet-ve-nguyen-nhan-va-cach-dieu-tri.tsx"); } },
  "./suc-khoe/dau-nhuc-xuong-khop-nguyen-nhan-trieu-chung-va-cach-dieu-tri.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/dau-nhuc-xuong-khop-nguyen-nhan-trieu-chung-va-cach-dieu-tri.tsx"); } },
  "./suc-khoe/index.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/index.tsx"); } },
  "./suc-khoe/lam-sao-de-giam-can-hieu-qua-huong-dan-chi-tiet-voi-giai-thich-y-hoc.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/lam-sao-de-giam-can-hieu-qua-huong-dan-chi-tiet-voi-giai-thich-y-hoc.tsx"); } },
  "./suc-khoe/lam-the-nao-de-ha-sot-phuong-phap-va-loi-khuyen-huu-ich.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/lam-the-nao-de-ha-sot-phuong-phap-va-loi-khuyen-huu-ich.tsx"); } },
  "./suc-khoe/nguyen-nhan-cua-chong-mat-phan-tich-toan-dien-va-chi-tiet.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/nguyen-nhan-cua-chong-mat-phan-tich-toan-dien-va-chi-tiet.tsx"); } },
  "./suc-khoe/nguyen-nhan-gay-tang-huyet-ap-nhung-dieu-can-biet.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/nguyen-nhan-gay-tang-huyet-ap-nhung-dieu-can-biet.tsx"); } },
  "./suc-khoe/thuoc-tieu-duong-giai-phap-hieu-qua-trong-quan-ly-benh-tieu-duong.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/thuoc-tieu-duong-giai-phap-hieu-qua-trong-quan-ly-benh-tieu-duong.tsx"); } },
  "./suc-khoe/trieu-chung-benh-tim-nhung-dau-hieu-canh-bao-khong-nen-bo-qua.tsx": { enumerable: true, get() { return require("/github/workspace/app/suc-khoe/trieu-chung-benh-tim-nhung-dau-hieu-canh-bao-khong-nen-bo-qua.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;