import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Animated } from "react-native";

interface SideBarProviderProps {
  children: React.ReactNode;
  sidebar?: React.ReactNode;
}

interface SideBarContext {
  visible: boolean;
  open: () => void;
  close: () => void;
}

const SideBarContext = createContext<SideBarContext>({
  visible: false,
  open: () => {},
  close: () => {},
});

export function useSideBar() {
  return useContext(SideBarContext);
}

export function SideBarProvider(props: SideBarProviderProps) {
  const { children, sidebar } = props;
  const [visible, setVisible] = useState(false);
  const slide = useRef(new Animated.Value(visible ? 1 : 0)).current;

  useEffect(() => {
    Animated.spring(slide, {
      toValue: visible ? 1 : 0,
      useNativeDriver: false,
      overshootClamping: true,
    }).start();
  }, [visible, slide]);

  const handleOpen = useCallback(() => {
    setVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <SideBarContext.Provider
      value={{
        visible,
        open: handleOpen,
        close: handleClose,
      }}
    >
      {children}
      <Animated.View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          maxWidth: 320,
          width: "100%",
          transform: [
            {
              translateX: slide.interpolate({
                inputRange: [0, 1],
                outputRange: [-320, 0],
              }),
            },
          ],
        }}
      >
        {sidebar}
      </Animated.View>
    </SideBarContext.Provider>
  );
}
