import React, { useRef } from "react";
import { View, Animated, Easing, StyleSheet } from "react-native";

interface LoadingIndicatorProps {
  color?: string;
  size?: number;
}

export function LoadingIndicator(props: LoadingIndicatorProps) {
  const { color = "#000", size = 8 } = props;

  const dots = useRef(
    Array.from({ length: 3 }, (_) => new Animated.Value(0)),
  ).current;

  React.useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.stagger(
          200,
          dots.map((dot) =>
            Animated.timing(dot, {
              toValue: 1,
              duration: 300,
              easing: Easing.linear,
              useNativeDriver: true,
            }),
          ),
        ),
        Animated.stagger(
          200,
          dots.map((dot) =>
            Animated.timing(dot, {
              toValue: 0,
              duration: 300,
              easing: Easing.linear,
              useNativeDriver: true,
            }),
          ),
        ),
        Animated.delay(500),
      ]),
    ).start();
  }, [dots]);

  return (
    <View style={[styles.container, { gap: size / 2 }]}>
      {dots.map((value, index) => (
        <Animated.View
          key={index}
          style={[
            styles.circle,
            { backgroundColor: color, width: size, height: size },
            {
              transform: [
                {
                  translateY: value.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, -8],
                  }),
                },
              ],
            },
          ]}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  circle: {
    borderRadius: 999,
  },
});
