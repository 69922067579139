import { EmptyPreview } from "@/components/admin/articles/empty_preview";
import { GenerateForm } from "@/components/admin/articles/generate_form";
import { Preview } from "@/components/admin/articles/preview";
import { colors } from "@/components/colors";
import { useForm } from "@/hooks/use_form";
import { useRouter } from "expo-router";
import { View } from "react-native";
import { ButtonCTA } from "@/components/button_cta";
import { useMutation } from "@/helpers/api";
import { api } from "@/server/api";

export default function ArticleNewPage() {
  const router = useRouter();
  const create = useMutation(api.articles.create);

  const { values, submit, submitting, setFieldValues } = useForm({
    initialValues: {
      topic: "",
      slug: "",
      title: "",
      description: "",
      content: "",
      imageUrl: "",
    },
    onSubmit: async (values) => {
      const article = await create(values);

      router.push(`/admin/articles/${article.id}`);
    },
  });

  return (
    <>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          backgroundColor: colors.background.default,
        }}
      >
        <View
          style={{
            width: 480,
            padding: 16,
            borderRightWidth: 1,
            borderColor: colors.border.default,
          }}
        >
          <GenerateForm onGenerate={setFieldValues} />
        </View>
        {values?.content ? <Preview article={values} /> : <EmptyPreview />}
      </View>
      <ButtonCTA
        loading={submitting}
        color="primary"
        title="Save"
        onPress={submit}
      />
    </>
  );
}
