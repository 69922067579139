import { StyleSheet, View } from "react-native";
import { Text } from "../text";
import { TextInput } from "../text_input";
import { InputContainer } from "../input_container";
import { colors } from "../colors";
import { useCallback, useState } from "react";

import { forwardRef } from "react";
import { ButtonCTA } from "../button_cta";

interface FeedbackFormProps {
  onCompleted?: (text: string) => void;
}

export const FeedbackForm = forwardRef<TextInput, FeedbackFormProps>(
  function FeedbackForm(props, ref) {
    const { onCompleted } = props;
    const [text, setText] = useState("");

    const handleSubmit = useCallback(() => {
      // Send feedback
      if (text.trim() === "") {
        return;
      }

      onCompleted?.(text);
      setText("");
    }, [text, onCompleted]);

    return (
      <View style={styles.container}>
        <Text color={colors.text.muted}>
          Bạn cần hỗ trợ, có ý tưởng cải thiện hoặc phát hiện lỗi?
        </Text>
        <Text color={colors.text.muted}>Gửi phản hồi cho chúng tôi!</Text>
        <View style={{ height: 16 }} />
        <InputContainer>
          <TextInput
            ref={ref}
            numberOfLines={4}
            value={text}
            onChangeText={setText}
            placeholder="Nhập tin nhắn"
            onSubmitEditing={handleSubmit}
          />
        </InputContainer>
        <View style={{ height: 16 }} />
        <View style={{ alignItems: "flex-end" }}>
          <ButtonCTA title="Gửi phản hồi" onPress={handleSubmit} />
        </View>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.border.default,
  },
});

// Need help, have an idea for improvement or found a bug? We’d love to hear from you!
