import { useCallback, useEffect, useRef } from "react";
import { Image } from "expo-image";
import { Modal, Platform, Pressable, StyleSheet, View } from "react-native";
import { useState } from "react";
import { Icon } from "./icon";
import { colors } from "./colors";
import { PressableHighlight } from "./pressable_highlight";
import { useScreenSize } from "@/hooks/use_screen_size";
import { PagerView } from "./pager_view";
import { i18n } from "@/helpers/i18n";

export interface ImageGalleryModalProps {
  images: { name: string; url: string; width: number; height: number }[];
  visible: boolean;
  initialIndex: number;
  onRequestClose: () => void;
}

export function ImageGalleryModal(props: ImageGalleryModalProps) {
  const { images, visible, initialIndex, onRequestClose } = props;
  const pagerViewRef = useRef<React.ElementRef<typeof PagerView>>(null);
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const screenSize = useScreenSize();

  const handleNext = useCallback(() => {
    if (currentIndex === images.length - 1) {
      return;
    }

    const nextIndex = (currentIndex + 1) % images.length;

    pagerViewRef.current?.setPage(nextIndex);
    setCurrentIndex(nextIndex);
  }, [currentIndex, images.length]);

  const handlePrev = useCallback(() => {
    if (currentIndex === 0) {
      return;
    }

    const prevIndex = (currentIndex - 1 + images.length) % images.length;

    pagerViewRef.current?.setPage(prevIndex);
    setCurrentIndex(prevIndex);
  }, [currentIndex, images.length]);

  useEffect(() => {
    if (Platform.OS !== "web") {
      return;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowLeft") {
        handlePrev();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNext, handlePrev]);

  return (
    <Modal visible={visible} transparent onRequestClose={onRequestClose}>
      <Pressable onPress={onRequestClose} style={styles.backdrop} />
      <View
        style={{
          position: "absolute",
          top: !screenSize.small ? 40 : 0,
          right: !screenSize.small ? 40 : 0,
          bottom: !screenSize.small ? 40 : 0,
          left: !screenSize.small ? 40 : 0,
          justifyContent: "center",
          backgroundColor: colors.background.default,
          borderRadius: 8,
        }}
      >
        <View
          style={{
            flex: 1,
            height: "100%",
            borderRadius: !screenSize.small ? 8 : 0,
          }}
        >
          <PagerView
            ref={pagerViewRef}
            initialPage={currentIndex}
            onPageSelected={(e) => setCurrentIndex(e.nativeEvent.position)}
          >
            {images.map((image) => (
              <View style={{ flex: 1, height: "100%" }}>
                <Image
                  alt={image.name}
                  key={image.url}
                  contentFit="contain"
                  source={image.url}
                  style={[
                    styles.image,
                    { borderRadius: !screenSize.small ? 8 : 0 },
                  ]}
                />
              </View>
            ))}
          </PagerView>
        </View>
        {currentIndex > 0 && (
          <PressableHighlight
            backgroundColor={colors.background.default}
            style={[styles.arrow, styles.arrowLeft]}
            onPress={handlePrev}
            tooltip={i18n.t("app.previous")}
          >
            <Icon name="arrow-left" />
          </PressableHighlight>
        )}
        {currentIndex < images.length - 1 && (
          <PressableHighlight
            backgroundColor={colors.background.default}
            style={[styles.arrow, styles.arrowRight]}
            onPress={handleNext}
            tooltip={i18n.t("app.next")}
          >
            <Icon name="arrow-right" />
          </PressableHighlight>
        )}
        <PressableHighlight
          backgroundColor={colors.background.default}
          style={[styles.x]}
          onPress={onRequestClose}
          tooltip={i18n.t("app.close")}
        >
          <Icon name="x" />
        </PressableHighlight>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  backdrop: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.background.backdrop,
  },
  x: {
    position: "absolute",
    borderRadius: 999,
    borderWidth: 1,
    borderColor: colors.border.default,
    width: 40,
    height: 40,
    top: 8,
    right: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  arrow: {
    position: "absolute",
    borderRadius: 999,
    borderWidth: 1,
    borderColor: colors.border.default,
    width: 48,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
  },
  arrowLeft: {
    left: 8,
  },
  arrowRight: {
    right: 8,
  },
  image: {
    flex: 1,
    width: "100%",
    backgroundColor: colors.background.backdropMuted,
    userSelect: "none",
    ...(Platform.OS === "web" && {
      userDrag: "none",
      WebkitUserDrag: "none",
      MozUserDrag: "none",
      OUserDrag: "none",
    }),
  },
});
