import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import { Slot } from "expo-router";

export default function HelpLayout() {
  return (
    <>
      <Header />
      <Slot />
      <Footer />
    </>
  );
}
