import styles from "./footer.module.css";

import { Href, Link } from "expo-router";
import { Text } from "./text";
import { Container } from "./container";

export function Footer() {
  return (
    <Container>
      <div className={styles.footer}>
        <div className={styles.leftWrapper}>
          <Text
            size="sm"
            numberOfLines={1}
          >{`© ${new Date().getFullYear()} YLan`}</Text>
          <Separator />
          <FooterLink href="/help/terms">Điều Khoản Sử Dụng</FooterLink>
          <Separator />
          <FooterLink href="/help/privacy">Chính Sách Bảo Mật</FooterLink>
          <Separator />
          <FooterLink href="/suc-khoe">Bài Viết Về Sức Khỏe</FooterLink>
        </div>

        <div>{/* <SocialLink href="/" icon="facebook" /> */}</div>
      </div>
    </Container>
  );
}

function Separator() {
  return <div>·</div>;
}

interface FooterLinkProps {
  href: Href;
  children: string;
}

function FooterLink(props: FooterLinkProps) {
  const { href, children } = props;

  return (
    <Link href={href} className={styles.footerLink}>
      <Text size="sm" numberOfLines={1}>
        {children}
      </Text>
    </Link>
  );
}
