import { ArticleTemp } from "./types";
import { ButtonCTA } from "@/components/button_cta";
import { InputContainer } from "@/components/input_container";
import { TextInput } from "@/components/text_input";
import { useMutation } from "@/helpers/api";
import { useForm } from "@/hooks/use_form";
import { api } from "@/server/api";
import { View } from "react-native";

interface GenerateFormProps {
  onGenerate: (values: ArticleTemp) => void;
}

export function GenerateForm(props: GenerateFormProps) {
  const { onGenerate } = props;
  const generate = useMutation(api.articles.generate);

  const { submit, setFieldValue, errors, values, submitting } = useForm({
    initialValues: {
      topic: "",
    },
    validate: ({ topic }) => {
      if (!topic.trim().length) {
        return { topic: "Topic is required" };
      }
    },
    onSubmit: async ({ topic }) => {
      const result = await generate({ topic });
      onGenerate(result);
    },
  });

  return (
    <View>
      <InputContainer label="Topic" error={errors.topic}>
        <TextInput value={values.topic} onChangeText={setFieldValue("topic")} />
      </InputContainer>
      <View style={{ height: 16 }} />
      <ButtonCTA loading={submitting} title="Generate" onPress={submit} />
    </View>
  );
}
