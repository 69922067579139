import styles from "./above_fold_section.module.css";
import React, { forwardRef } from "react";
import { Heading } from "../heading";
import { useRouter } from "expo-router";
import { useComposerHandlers } from "../chat/use_composer_handlers";
import { Composer, ComposerRef } from "../chat/composer";
import { Container } from "../container";
import { GradientBlur } from "./gradient_blur";
import { analytics } from "@/helpers/analytics";
import { findOrCreateDeviceId } from "@/helpers/device";
import { Text } from "../text";
import { useMutation } from "@/helpers/api";
import { api } from "@/server/api";

export const AboveFoldSection = forwardRef<ComposerRef>(
  function AboveFoldSection(_, ref) {
    const createThread = useMutation(api.threads.create);
    const router = useRouter();

    const {
      text,
      files,
      handleChangeText,
      handleSend,
      handleAddFiles,
      handleRemoveFile,
    } = useComposerHandlers({
      onSend: async (text, files) => {
        const deviceId = await findOrCreateDeviceId();

        // If there are no files, we just navigate to the chat screen which will create a thread with the text
        // This is faster for UX because we don't have to wait for the files to upload or the thread to be created
        if (!files.length) {
          router.push(
            `/c/?q=${encodeURIComponent(text)}&ref=homepage-above-fold`,
          );
          return;
        }

        const threadId = await createThread({ text, files, deviceId });

        analytics.track("Send Message", {
          "Thread ID": threadId,
          Text: text,
          Files: files.map((f) => f.url),
        });
        router.push(`/c/${threadId}`);
      },
    });

    return (
      <div className={styles.wrapper}>
        <div className={styles.headingWrapper}>
          <Container>
            <Heading>Bạn có câu hỏi gì về sức khỏe?</Heading>
            <div style={{ height: 16 }} />
            <Text color="muted" align="center">
              Trợ lý sức khỏe thông minh của bạn – tư vấn và cung cấp thông tin
              y tế đáng tin cậy. Hỗ trợ online miễn phí để bạn luôn an tâm chăm
              sóc sức khỏe bản thân và gia đình
            </Text>
          </Container>
        </div>
        <div className={styles.composerWrapper}>
          <div className={styles.composerInnerWrapper}>
            <Composer
              ref={ref}
              text={text}
              onChangeText={handleChangeText}
              onAddFiles={handleAddFiles}
              onRemoveFile={handleRemoveFile}
              files={files}
              sendOnEnter={true}
              onSend={handleSend}
            />
          </div>
        </div>
        <GradientBlur />
      </div>
    );
  },
);
