.container {
  z-index: 2;
  position: fixed;
  left: 0;
  right: 0;
  height: 80px;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: all 0.4s ease;
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.85);
}

.containerInner {
  width: 100%;
  max-width: 1400px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logoLink {
  height: 100%;
  display: flex;
  align-items: center;
}

.transparent {
  background: transparent;
  backdrop-filter: initial;
}
