import React, { useCallback, useRef, useState } from "react";

import {
  FlatList,
  View,
  StyleSheet,
  ListRenderItemInfo,
  ListRenderItem,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Button,
} from "react-native";
import { Text } from "../text";

export interface Message {
  text: string;
}

export interface MessageProps<T extends Message> {
  message: T;
}

export interface ScrollToBottomProps {
  onPress: () => void;
}

export interface MessageListProps<TMessage extends Message> {
  messages?: TMessage[];
  onLoadPreviousMessages?: () => void;
  keyExtractor?: (message: TMessage) => string;
  renderMessage?: (props: MessageProps<TMessage>) => React.ReactElement;
  renderScrollToBottom?: (props: ScrollToBottomProps) => React.ReactElement;
}

export function MessageList<TMessage extends Message>(
  props: MessageListProps<TMessage>,
) {
  const {
    messages,
    keyExtractor,
    onLoadPreviousMessages,
    renderScrollToBottom,
    renderMessage,
  } = props;
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const listRef = useRef<FlatList<TMessage>>(null);

  const renderRow: ListRenderItem<TMessage> = useCallback(
    ({ item }: ListRenderItemInfo<TMessage>) => {
      if (renderMessage) {
        return renderMessage({ message: item });
      }

      return (
        <View>
          <Text>{item.text}</Text>
        </View>
      );
    },
    [renderMessage],
  );

  const handleScroll = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (!showScrollToBottom && e.nativeEvent.contentOffset.y > 100) {
        setShowScrollToBottom(true);
      } else if (showScrollToBottom && e.nativeEvent.contentOffset.y <= 100) {
        setShowScrollToBottom(false);
      }
    },
    [showScrollToBottom],
  );

  const handleScrollToBottomPress = useCallback(() => {
    listRef.current?.scrollToOffset({ animated: true, offset: 0 });
  }, []);

  return (
    <View style={styles.container}>
      <FlatList
        inverted
        ref={listRef}
        keyExtractor={keyExtractor}
        data={messages}
        renderItem={renderRow}
        ItemSeparatorComponent={() => <View style={{ height: 40 }} />}
        ListHeaderComponent={() => <View style={{ height: 40 }} />}
        // Offset for the header
        ListFooterComponent={() => <View style={{ height: 120 }} />}
        onEndReached={onLoadPreviousMessages}
        onScroll={handleScroll}
      />
      {showScrollToBottom && (
        <View style={styles.scrollToBottomWrapper}>
          {renderScrollToBottom ? (
            renderScrollToBottom({ onPress: handleScrollToBottomPress })
          ) : (
            <Button
              title="Scroll to bottom"
              onPress={handleScrollToBottomPress}
            />
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollToBottomWrapper: {
    position: "absolute",
    bottom: 16,
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "center",
  },
});
