import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";

interface ContainerProps {
  children: React.ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
}

export function Container(props: ContainerProps) {
  const { children, containerStyle, style } = props;

  return (
    <View
      style={[
        { flex: 1, flexDirection: "row", justifyContent: "center" },
        containerStyle,
      ]}
    >
      <View style={[{ flex: 1, paddingHorizontal: 16, maxWidth: 768 }, style]}>
        {children}
      </View>
    </View>
  );
}
