import { Container } from "@/components/container";
import { Paragraph } from "@/components/paragraph";
import { Section } from "@/components/section";
import { Text } from "@/components/text";

export default function Privacy() {
  return (
    <div>
      <Section title="Chính Sách Bảo Mật">
        <Container>
          <Text>
            Quyền riêng tư của bạn rất quan trọng đối với chúng tôi. Bằng cách
            sử dụng các dịch vụ về sức khỏe của YLan, bạn đồng ý với các điều
            khoản bảo mật sau đây:
          </Text>
          <Paragraph>
            <Text bold>Thu Thập Dữ Liệu</Text>: Dịch vụ của YLan có thể thu thập
            thông tin bạn cung cấp, bao gồm các câu hỏi về sức khỏe, tài liệu
            được tải lên, và dữ liệu tương tác, nhằm cải thiện phản hồi và trải
            nghiệm người dùng. Chúng tôi không thu thập thông tin cá nhân nhạy
            cảm như họ tên đầy đủ, địa chỉ, hoặc chi tiết liên hệ.
          </Paragraph>
          <Paragraph>
            <Text bold>Sử Dụng Dữ Liệu</Text>: Thông tin của bạn chỉ được sử
            dụng để cung cấp thông tin sức khỏe liên quan, cải thiện độ chính
            xác của dịch vụ, và nâng cao dịch vụ của chúng tôi. Dữ liệu này
            không được chia sẻ với bên thứ ba, trừ khi được yêu cầu bởi pháp
            luật hoặc vì mục đích tuân thủ.
          </Paragraph>
          <Paragraph>
            <Text bold>Bảo Mật</Text>: Chúng tôi áp dụng các biện pháp bảo mật
            theo tiêu chuẩn ngành để bảo vệ dữ liệu của bạn. Mặc dù có những
            biện pháp bảo vệ này, không có dịch vụ trực tuyến nào là hoàn toàn
            bảo mật, và chúng tôi không thể đảm bảo an toàn tuyệt đối cho dữ
            liệu.
          </Paragraph>
          <Paragraph>
            <Text bold>Ẩn Danh Hóa</Text>: Dữ liệu thu thập được ẩn danh khi có
            thể để bảo vệ quyền riêng tư của bạn. Các câu hỏi về sức khỏe và
            tương tác được lưu trữ mà không có thông tin nhận dạng.
          </Paragraph>
          <Paragraph>
            <Text bold>Dịch Vụ Bên Thứ Ba</Text>: Dịch vụ của YLan có thể sử
            dụng công cụ bên thứ ba để xử lý và cải thiện dữ liệu. Các bên thứ
            ba này tuân thủ các tiêu chuẩn bảo mật dữ liệu nghiêm ngặt để đảm
            bảo thông tin của bạn được xử lý một cách có trách nhiệm.
          </Paragraph>
          <Paragraph>
            <Text bold>Sự Đồng Ý Của Bạn</Text>: Bằng cách sử dụng dịch vụ của
            YLan, bạn đồng ý với việc thu thập và sử dụng dữ liệu như được nêu
            trong chính sách này. Nếu bạn không đồng ý với bất kỳ phần nào của
            chính sách này, vui lòng ngừng sử dụng dịch vụ của chúng tôi.
          </Paragraph>
          <Paragraph>
            Nếu bạn muốn xóa dữ liệu cá nhân của mình, vui lòng liên hệ với
            chúng tôi qua email tại: <Text bold>help@ylan.vn</Text>
          </Paragraph>
        </Container>
      </Section>
    </div>
  );
}

// Privacy Policy

// Your privacy is important to us. By using YLan’s health AI services, you agree to the following privacy terms:

// Data Collection: YLan’s services may collect information you provide, including health questions, uploaded documents, and interaction data, to improve responses and user experience. We do not collect sensitive personal information such as full names, addresses, or contact details.

// Data Usage: Your information is used solely to deliver relevant health information, improve service accuracy, and enhance our offerings. It is not shared with third parties except as required by law or for compliance purposes.

// Security: We employ industry-standard security measures to protect your data. Despite these safeguards, no online service is entirely secure, and we cannot guarantee absolute data security.

// Anonymization: Data collected is anonymized where possible to further protect your privacy. Health questions and interactions are stored without identifying information.

// Third-Party Services: YLan’s services may use third-party tools for data processing and improvement. These third parties adhere to strict data privacy standards to ensure your information is handled responsibly.

// Your Consent: By using YLan’s services, you consent to data collection and usage as outlined in this policy. If you do not agree with any part of this policy, please discontinue use of our services.
