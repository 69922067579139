import styles from "./key_features_section.module.css";
import { Text } from "../text";
import { Section } from "../section";
import { Container } from "../container";
import { Image, ImageSource } from "expo-image";
import { useCallback } from "react";
import { useRouter } from "expo-router";
import { colors } from "../colors";

export function KeyFeaturesSection() {
  const router = useRouter();

  const handleSuggestionClick = useCallback(
    async (text: string) => {
      router.push(
        `/c/?q=${encodeURIComponent(text)}&ref=homepage-key-features`,
      );
    },
    [router],
  );

  return (
    <Section title="Khám Phá Các Tính Năng Nổi Bật">
      <Container style={{ maxWidth: 600 }}>
        <div className={styles.featuresWrapper}>
          {features.map((feature) => (
            <Feature
              key={feature.title}
              feature={feature}
              onSuggestionClick={handleSuggestionClick}
            />
          ))}
        </div>
      </Container>
    </Section>
  );
}

interface FeatureProps {
  feature: Feature;
  onSuggestionClick: (text: string) => void;
}

function Feature(props: FeatureProps) {
  const { feature, onSuggestionClick } = props;
  const { source, title, description, suggestions } = feature;

  return (
    <div className={styles.featureContainer}>
      <div>
        <div className={styles.featureImage}>
          <Image
            alt={title}
            source={source}
            style={{ flex: 1, width: "100%" }}
          />
        </div>
        <Text size="lg" bold>
          {title}
        </Text>
        <div style={{ height: 8 }} />
        <Text color={colors.text.muted}>{description}</Text>
      </div>
      <div className={styles.suggestionsWrapper}>
        {suggestions.map((suggestion) => (
          <Suggestion key={suggestion} onClick={onSuggestionClick}>
            {suggestion}
          </Suggestion>
        ))}
      </div>
    </div>
  );
}

interface SuggestionsProps {
  children: string;
  onClick: (text: string) => void;
}

function Suggestion(props: SuggestionsProps) {
  const { children, onClick } = props;

  const handleClick = useCallback(() => {
    onClick(children);
  }, [children, onClick]);

  return (
    <span onClick={handleClick} className={styles.suggestion}>
      {children}
    </span>
  );
}

interface Feature {
  source: ImageSource;
  title: string;
  description: string;
  suggestions: string[];
}

const features: Feature[] = [
  {
    source: require("../../assets/images/symptoms_resized.png"),
    title: "Phân Tích Về Triệu Chứng Sức Khỏe",
    description:
      "Nhập các triệu chứng bạn đang gặp phải để nhận giải thích, nguyên nhân có thể và lời khuyên hữu ích ngay lập tức.",
    suggestions: [
      "Tôi bị đau họng và ho, nguyên nhân có thể là gì?",
      "Đau bụng liên tục có đáng lo không?",
      "Khi nào nên lo lắng về sốt cao?",
      "Cảm thấy chóng mặt khi đứng lên có bình thường không?",
      "Đau ngực có thể là dấu hiệu của vấn đề gì?",
    ],
  },
  {
    source: require("../../assets/images/treatment_resized.png"),
    title: "Thông Tin Về Thuốc Và Điều Trị",
    description:
      "Tìm hiểu thông tin rõ ràng, đáng tin cậy về thuốc, cách sử dụng và các tác dụng phụ có thể xảy ra.",
    suggestions: [
      "Tác dụng phụ của Paracetamol là gì?",
      "Có an toàn không khi uống Ibuprofen với rượu?",
      "Tôi nên dùng thuốc tiểu đường như thế nào?",
      "Tác hại của dừng kháng sinh giữa chừng là gì?",
    ],
  },
  {
    source: require("../../assets/images/health_tips_resized.png"),
    title: "Tư Vấn Sức Khỏe Hàng Ngày",
    description:
      "Nhận các gợi ý về dinh dưỡng, tập luyện, sức khỏe tinh thần và cách phòng ngừa bệnh tật.",
    suggestions: [
      "Làm thế nào để cải thiện chất lượng giấc ngủ?",
      "Những món ăn nhẹ lành mạnh cho việc giảm cân?",
      "Mẹo giảm căng thẳng trong công việc?",
      "Tôi nên tập thể dục bao nhiêu lần một tuần?",
      "Tôi nên bổ sung vitamin nào mỗi ngày?",
    ],
  },
  {
    source: require("../../assets/images/healthcare_finder_resized.png"),
    title: "Tìm Cơ Sở Y Tế Gần Bạn",
    description:
      "Xác định bệnh viện, nhà thuốc và chuyên gia y tế gần nhất để đáp ứng nhu cầu chăm sóc sức khỏe của bạn.",
    suggestions: [
      "Cơ sở khám chữa bệnh gần nhất ở đâu?",
      "Tìm nha sĩ gần tôi.",
      "Có trung tâm cấp cứu nào mở cửa bây giờ không?",
      "Làm thế nào để tìm nhà thuốc mở cửa 24 giờ?",
      "Tôi có thể tiêm vắc-xin cúm ở đâu gần đây?",
    ],
  },
];
