import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import {
  Animated,
  View,
  PanResponder,
  StyleSheet,
  NativeSyntheticEvent,
} from "react-native";
import RNPagerView, { PagerViewProps } from "react-native-pager-view";

import { AutoSizer } from "./auto_sizer";

type PagerViewHandle = Pick<
  RNPagerView,
  "setPage" | "setPageWithoutAnimation" | "setScrollEnabled"
>;

export const PagerView = forwardRef<PagerViewHandle, PagerViewProps>(
  function PagerView(props, ref) {
    const {
      children,
      initialPage = 0,
      scrollEnabled = true,
      overdrag = false,
      onPageSelected,
    } = props;
    const pageWidthRef = useRef(0);
    const pan = useRef(new Animated.Value(0)).current;
    const offset = useRef(0);
    const pageRef = useRef(initialPage);
    const scroll = useRef(!!scrollEnabled);

    const setPage = useCallback(
      (newPage: number) => {
        if (newPage < 0 || newPage >= React.Children.count(children)) {
          return; // Ignore invalid pageRef
        }

        pageRef.current = newPage;

        Animated.spring(pan, {
          toValue: -newPage * pageWidthRef.current,
          useNativeDriver: false,
          bounciness: 0,
        }).start();
      },
      [children, pan],
    );

    const setPageWithoutAnimation = useCallback(
      (newPage: number) => {
        if (newPage < 0 || newPage >= React.Children.count(children)) {
          return; // Ignore invalid pageRef
        }

        pageRef.current = newPage;
        pan.setValue(-newPage * pageWidthRef.current);
      },
      [children, pan],
    );

    const setScrollEnabled = useCallback((enabled: boolean) => {
      scroll.current = enabled;
    }, []);

    useEffect(() => {
      // Update the scroll when the scrollEnabled prop changes
      scroll.current = !!scrollEnabled;
    }, [scrollEnabled]);

    useImperativeHandle(ref, () => ({
      setPage,
      setPageWithoutAnimation,
      setScrollEnabled,
    }));

    const panResponder = useRef(
      PanResponder.create({
        onStartShouldSetPanResponder: () => scroll.current,
        onPanResponderMove: (_evt, gestureState) => {
          let newOffset =
            gestureState.dx - pageRef.current * pageWidthRef.current;

          // Prevent overdragging at the start
          if (
            overdrag === false &&
            pageRef.current === 0 &&
            gestureState.dx > 0
          ) {
            newOffset = 0;
          }

          // Prevent overdragging at the end
          if (
            overdrag === false &&
            pageRef.current === React.Children.count(children) - 1 &&
            gestureState.dx < 0
          ) {
            newOffset = -pageRef.current * pageWidthRef.current;
          }

          offset.current = newOffset;
          pan.setValue(newOffset);
        },
        onPanResponderRelease: (evt, gestureState) => {
          // Consider both the offset and the swipe velocity for the snap. Adjust according to preference
          const swipeVelocityThreshold = 0.5;
          const swipeDistanceThreshold = pageWidthRef.current / 4;

          let newPage = pageRef.current;

          if (
            Math.abs(gestureState.vx) > swipeVelocityThreshold ||
            Math.abs(gestureState.dx) > swipeDistanceThreshold
          ) {
            newPage =
              gestureState.dx > 0
                ? Math.max(pageRef.current - 1, 0)
                : Math.min(
                    pageRef.current + 1,
                    React.Children.count(children) - 1,
                  );
          }

          pageRef.current = newPage;

          Animated.spring(pan, {
            toValue: -newPage * pageWidthRef.current,
            useNativeDriver: false,
            bounciness: 0,
          }).start(() => {
            // Update the offset after the animation completes
            offset.current = -newPage * pageWidthRef.current;
          });

          onPageSelected?.({
            nativeEvent: { position: newPage },
          } as NativeSyntheticEvent<Readonly<{ position: number }>>);
        },
      }),
    ).current;

    const handleResize = useCallback(
      (size: { width: number; height: number }) => {
        const width = size.width;

        pan.setValue(-pageRef.current * width);
        offset.current = -pageRef.current * width;
        pageWidthRef.current = width;
      },
      [pan],
    );

    return (
      <AutoSizer onResize={handleResize}>
        {({ width }) => (
          <View style={styles.container}>
            <Animated.View
              style={{
                flex: 1,
                flexDirection: "row",
                transform: [{ translateX: pan }],
              }}
              {...panResponder.panHandlers}
            >
              {React.Children.map(children, (child) => (
                <View style={{ width }}>{child}</View>
              ))}
            </Animated.View>
          </View>
        )}
      </AutoSizer>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: "hidden",
  },
});
