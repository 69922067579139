.footer {
  padding-top: 40px;
  border-top: 1px solid var(--border-default);
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 16px;
}

.leftWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.rightWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}

.footerLink {
  text-decoration: none;
}

.socialLink {
  text-decoration: none;
}

@media (max-width: 767px) {
  .footer {
    padding-bottom: 104px;
    flex-direction: column;
  }
}
