import styles from "./highlighted_benefits_section.module.css";
import { Text } from "../text";
import { Section } from "../section";
import { Container } from "../container";
import { Image, ImageSource } from "expo-image";
import { colors } from "../colors";

export function HighlightedBenefitsSection() {
  return (
    <Section title="Lợi Ích Dành Riêng Cho Bạn">
      <Container>
        <div className={styles.benefitWrapper}>
          <Benefit
            source={require("../../assets/images/privacy_resized.png")}
            title="Bảo Mật Và Đáng Tin Cậy"
            description="Mọi cuộc trò chuyện đều được bảo mật tuyệt đối. Thông tin cá nhân của bạn sẽ luôn được giữ kín và không bao giờ bị chia sẻ."
          />
          <Benefit
            source={require("../../assets/images/evidence_resized.png")}
            title="Thông Tin Y Khoa Chính Xác"
            description="Câu trả lời được xây dựng dựa trên các nguồn và hướng dẫn y khoa uy tín, giúp bạn an tâm với thông tin nhận được."
          />
          <Benefit
            source={require("../../assets/images/availability_resized.png")}
            title="Tư Vấn Online Miến Phí, Nhanh Chóng"
            description="Nhận tư vấn sức khỏe bất cứ khi nào bạn cần mà không cần chờ đợi, không cần đặt lịch hẹn. Chúng tôi luôn sẵn sàng hỗ trợ bạn."
          />
        </div>
      </Container>
    </Section>
  );
}

interface BenefitProps {
  source: ImageSource;
  title: string;
  description: string;
}

function Benefit(props: BenefitProps) {
  const { source, title, description } = props;

  return (
    <div className={styles.benefit}>
      <div className={styles.benefitImage}>
        <Image alt={title} source={source} style={{ flex: 1, width: "100%" }} />
      </div>
      <div className={styles.benefitInner}>
        <Text size="lg" bold>
          {title}
        </Text>
        <div style={{ height: 8 }} />
        <Text color={colors.text.mutedExtra}>{description}</Text>
      </div>
    </div>
  );
}
