import { forwardRef } from "react";
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { Text } from "./text";
import { PressableHighlight } from "./pressable_highlight";
import { colors } from "./colors";

export type ButtonCTAColor = "default" | "primary" | "destructive";
export type ButtonShape = "rounded" | "pill";

interface ButtonCTAProps {
  onPress?: () => void;
  title: string;
  color?: ButtonCTAColor;
  disabled?: boolean;
  loading?: boolean;
  style?: StyleProp<ViewStyle>;
  shape?: ButtonShape;
}

export const ButtonCTA = forwardRef(function ButtonCTA(
  props: ButtonCTAProps,
  ref: React.Ref<View>,
): JSX.Element {
  const {
    onPress,
    title,
    style,
    disabled,
    loading,
    shape = "rounded",
    color = "default",
  } = props;

  return (
    <PressableHighlight
      onPress={onPress}
      backgroundColor={colors.button[color].color}
      disabled={disabled || loading}
      style={[styles.button, styles[shape], style]}
      ref={ref}
    >
      {loading ? (
        <ActivityIndicator color={colors.button[color].text} />
      ) : (
        <Text bold color={colors.button[color].text}>
          {title}
        </Text>
      )}
    </PressableHighlight>
  );
});

const styles = StyleSheet.create({
  button: {
    userSelect: "none",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 12,
    paddingHorizontal: 32,
  },
  rounded: {
    borderRadius: 8,
  },
  pill: {
    borderRadius: 999,
  },
});
