import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from "react";
import { Text } from "./text";
import { Animated, Platform, StyleSheet, View } from "react-native";
import { colors } from "./colors";
import { uuid } from "@/helpers/uuid";

type ToastId = string;
type ToastStatus = "success" | "error" | "warning";

interface ToastSettings {
  /**
   * Duration for how long the toast should stay active.
   * @default 3000
   */
  duration?: number;

  status: ToastStatus;

  message: string;
}

interface ToastInstance extends ToastSettings {
  /**
   * Assign an id to the toast so you can remove it later.
   */
  id: ToastId;

  /**
   * Callback invoked when the duration is up.
   */
  onRemove: () => void;
}

interface ToastContextValue {
  notify: (toastSettings: ToastSettings) => ToastInstance;
  error: (message: string) => ToastInstance;
  success: (message: string) => ToastInstance;
  warning: (message: string) => ToastInstance;
  removeToast: (id: ToastId) => void;
}

const defaultToastInstance: ToastInstance = {
  id: "uuid",
  onRemove: () => null,
  message: "",
  status: "success",
};

const defaultToastContext: ToastContextValue = {
  error: () => defaultToastInstance,
  notify: () => defaultToastInstance,
  removeToast: (id) => {
    return;
  },
  success: () => defaultToastInstance,
  warning: () => defaultToastInstance,
};

const ToastContext = createContext(defaultToastContext);

export const useToast = () => {
  return useContext(ToastContext);
};

interface ToastProviderState {
  toasts: ToastInstance[];
}

const initialState: ToastProviderState = {
  toasts: [],
};

enum ActionType {
  ADD_TOAST = "ADD_TOAST",
  REMOVE_TOAST = "REMOVE_TOAST",
}

type Action =
  | { type: ActionType.ADD_TOAST; payload: { toast: ToastInstance } }
  | { type: ActionType.REMOVE_TOAST; payload: { id: ToastId } };

const reducer = (state: ToastProviderState, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_TOAST:
      return { toasts: [...state.toasts, action.payload.toast] };
    case ActionType.REMOVE_TOAST:
      return {
        toasts: state.toasts.filter((toast) => toast.id !== action.payload.id),
      };
    default:
      throw new Error("Invalid action type");
  }
};

interface ToastProviderProps {
  children?: React.ReactNode;
}

export const ToastProvider = (props: ToastProviderProps) => {
  const { children } = props;
  // Use reducer because we want access previous value of state
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const toastsRef = useRef(state.toasts);
  toastsRef.current = state.toasts;

  const createToastInstance = useCallback(
    (toastSettings: ToastSettings): ToastInstance => {
      const id = uuid();

      return {
        id,
        onRemove: () =>
          dispatch({ type: ActionType.REMOVE_TOAST, payload: { id } }),
        duration: toastSettings.duration,
        status: toastSettings.status,
        message: toastSettings.message,
      };
    },
    [],
  );

  const notify = useCallback(
    (toastSettings: ToastSettings) => {
      const toastInstance = createToastInstance(toastSettings);

      dispatch({
        type: ActionType.ADD_TOAST,
        payload: { toast: toastInstance },
      });

      return toastInstance;
    },
    [dispatch, createToastInstance],
  );

  const contextValue = useMemo(
    () => ({
      notify: (toastSettings: ToastSettings) => notify(toastSettings),
      error: (message: string) => notify({ message, status: "error" }),
      success: (message: string) => notify({ message, status: "success" }),
      warning: (message: string) => notify({ message, status: "warning" }),
      removeToast: (id: ToastId) =>
        dispatch({ type: ActionType.REMOVE_TOAST, payload: { id } }),
    }),
    [dispatch, notify],
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <View style={styles.root}>
        {state.toasts.map((toast) => {
          return (
            <Toast
              key={toast.id}
              id={toast.id}
              duration={toast.duration}
              status={toast.status}
              message={toast.message}
              onRemove={toast.onRemove}
            />
          );
        })}
      </View>
    </ToastContext.Provider>
  );
};

interface ToastProps extends ToastInstance {}

const Toast = (props: ToastProps) => {
  const { onRemove, duration = 5000, status, message } = props;

  const opacity = useRef(new Animated.Value(0)).current;
  const offset = useRef(new Animated.Value(-500)).current;

  React.useEffect(() => {
    Animated.parallel([
      Animated.spring(opacity, {
        toValue: 1,
        overshootClamping: true,
        useNativeDriver: true,
      }),
      Animated.spring(offset, {
        toValue: 0,
        overshootClamping: true,
        useNativeDriver: true,
      }),
    ]).start();

    const timer = setTimeout(() => {
      Animated.spring(offset, {
        toValue: -100,
        overshootClamping: true,
        useNativeDriver: true,
      }).start(() => {
        onRemove();
      });
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onRemove, opacity, offset]);

  return (
    <Animated.View
      style={[
        styles.toast,
        { backgroundColor: colors.status[status].background },
        { opacity, transform: [{ translateY: offset }] },
      ]}
    >
      <Text>{message}</Text>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  root: {
    left: 32,
    marginBottom: 0,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 0,
    maxWidth: 560,
    position: Platform.OS === "web" ? "fixed" : "absolute",
    flexDirection: "column",
    gap: 16,
    paddingTop: 16,
    right: 32,
    top: 0,
    zIndex: 2,
  },
  toast: {
    padding: 16,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "white",
    position: "relative",
    marginBottom: 16,
  },
});
