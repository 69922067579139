import { ScrollView, StyleSheet, View } from "react-native";
import { ThreadList } from "../chat/thread_list";
import { useSideBar } from "./side_bar_provider";
import { ButtonFlat } from "../button_flat";
import { useCallback, useEffect, useRef, useState } from "react";
import { colors } from "../colors";
import { useRouter } from "expo-router";
import { useToast } from "../toast";
import { TextInput } from "../text_input";
import { analytics } from "@/helpers/analytics";
import { Popover } from "../popover";
import { FeedbackForm } from "./feedback_form";
import { ThreadDoc } from "@/server/db/threads";

export function SideBarMenu() {
  const { close } = useSideBar();
  const router = useRouter();

  const feedbackFormRef = useRef<TextInput>(null);
  const [visible, setVisible] = useState(false);
  const toast = useToast();

  const handleCollapseSideBar = useCallback(() => {
    close();
  }, [close]);

  const handleOpenThread = useCallback(
    (thread: ThreadDoc) => {
      analytics.track("Thread Opened", {
        "Thread ID": thread.id,
        Title: thread.title,
      });

      router.push(`/c/${thread.id}`);
      close();
    },
    [router, close],
  );

  useEffect(() => {
    if (visible) {
      feedbackFormRef.current?.focus();
    }
  }, [visible]);

  const handleSendFeedbackButtonPress = useCallback(() => {
    analytics.track("Send Feedback Button Pressed");
    setVisible(true);
  }, []);

  const handleFeedbackFormCompleted = useCallback(
    (message: string) => {
      analytics.track("Feedback Sent", { Message: message });
      toast.success("Phản hồi của bạn đã được gửi");
      setVisible(false);
    },
    [toast],
  );

  return (
    <View style={styles.root}>
      <View style={styles.top}>
        <ButtonFlat
          icon="layout-sidebar-left-collapse"
          onPress={handleCollapseSideBar}
        />
        {/* <ButtonFlat icon="edit" onPress={handleNewThread} /> */}
      </View>
      <ScrollView style={styles.threadContainer}>
        <ThreadList onPress={handleOpenThread} />
      </ScrollView>
      <View style={styles.popoverContainer}>
        <Popover
          visible={visible}
          onRequestClose={() => setVisible(false)}
          content={
            <View style={styles.feedbackFormContainer}>
              <FeedbackForm
                ref={feedbackFormRef}
                onCompleted={handleFeedbackFormCompleted}
              />
            </View>
          }
          position="topLeft"
        >
          <ButtonFlat
            onPress={handleSendFeedbackButtonPress}
            title="Gửi phản hồi"
          />
        </Popover>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: colors.background.default,
    flex: 1,
    shadowOffset: colors.shadow.offset,
    shadowOpacity: colors.shadow.opacity,
    shadowRadius: colors.shadow.radius,
    shadowColor: colors.shadow.color,
  },

  top: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 16,
  },
  threadContainer: {
    padding: 16,
    flex: 1,
  },
  feedbackFormContainer: {
    width: 360,
  },
  popoverContainer: {
    padding: 16,
  },
});
