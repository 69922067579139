import { View } from "react-native";
import { Text } from "./text";
import { StyleSheet } from "react-native";

interface ParagraphProps {
  children: React.ReactNode;
}

export function Paragraph(props: ParagraphProps) {
  const { children } = props;

  return (
    <View style={styles.pargraph}>
      <Text>{children}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  pargraph: {
    paddingVertical: 8,
  },
});
