import { makeRedirectUri } from "expo-auth-session";
import { openAuthSessionAsync } from "expo-web-browser";
import { useCallback } from "react";
import { Button, Platform } from "react-native";

export function GoogleLoginButton() {
  const signIn = useCallback(async (provder: string, config: any) => {
    return { redirect: makeRedirectUri() };
  }, []);

  const handleSignIn = async () => {
    const redirectTo = makeRedirectUri();
    const { redirect } = await signIn("google", { redirectTo });

    if (Platform.OS === "web") {
      return;
    }

    const result = await openAuthSessionAsync(redirect!.toString(), redirectTo);

    if (result.type === "success") {
      const { url } = result;
      const code = new URL(url).searchParams.get("code")!;
      await signIn("google", { code });
    }
  };

  return <Button onPress={handleSignIn} title="Sign in with Google" />;
}
