import { ColorValue, Pressable, PressableProps, View } from "react-native";
import { forwardRef } from "react";
import { colors } from "./colors";
import { Text } from "./text";
import { StyleSheet } from "react-native";

interface PressableHighlightProps extends PressableProps {
  /**
   * This will be the color of the button that will be overwritten.
   * This will also override backgroundColor found in the style prop.
   */
  disabledColor?: string;
  backgroundColor?: string;
  tooltip?: string;
  tooltipPosition?: "top" | "left" | "right";
}

/**
 * A pressable component that highlights when hovered and pressed.
 * It will darken the `backgroundColor` prop when hovered and pressed.
 */
export const PressableHighlight = forwardRef<View, PressableHighlightProps>(
  function PressableHighlight(props, ref) {
    const {
      children,
      style,
      backgroundColor,
      disabledColor,
      disabled,
      tooltip,
      tooltipPosition = "top",
      ...pressableProps
    } = props;

    return (
      <Pressable
        ref={ref}
        disabled={disabled}
        style={({ pressed, hovered }) => {
          return [
            backgroundColor !== undefined
              ? {
                  backgroundColor: disabled
                    ? disabledColor || darkenColor(backgroundColor, -5)
                    : pressed
                      ? darkenColor(backgroundColor, 10)
                      : hovered
                        ? darkenColor(backgroundColor, 5)
                        : backgroundColor,
                }
              : {
                  backgroundColor: pressed
                    ? darkenColor("rgba(255,255,255,1)", 10)
                    : hovered
                      ? darkenColor("rgba(255,255,255,1)", 5)
                      : "rgba(255,255,255,0)",
                },
            typeof style === "function" ? style({ pressed, hovered }) : style,
          ];
        }}
        {...pressableProps}
      >
        {({ pressed, hovered }) => (
          <>
            {typeof children === "function"
              ? children({ pressed, hovered })
              : children}
            {hovered && tooltip && (
              <View style={[styles.tooltip, styles[tooltipPosition]]}>
                <Text numberOfLines={1} size={14} color={colors.tooltip.text}>
                  {tooltip}
                </Text>
              </View>
            )}
          </>
        )}
      </Pressable>
    );
  },
);

const styles = StyleSheet.create({
  tooltip: {
    position: "absolute",
    backgroundColor: colors.tooltip.backgroundColor,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 5,
    zIndex: 1,
  },
  top: {
    bottom: "100%",
    marginBottom: 8,
  },
  right: {
    left: "100%",
    marginLeft: 8,
  },
  left: {
    right: "100%",
    marginRight: 8,
  },
});

function darkenColor(color: string, percent: number): ColorValue {
  const clamp = (value: number, min: number, max: number) =>
    Math.min(Math.max(value, min), max);

  if (color.startsWith("#")) {
    let r = parseInt(color.slice(1, 3), 16);
    let g = parseInt(color.slice(3, 5), 16);
    let b = parseInt(color.slice(5, 7), 16);

    r = clamp(Math.floor(r * (1 - percent / 100)), 0, 255);
    g = clamp(Math.floor(g * (1 - percent / 100)), 0, 255);
    b = clamp(Math.floor(b * (1 - percent / 100)), 0, 255);

    return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
  } else if (color.startsWith("rgb")) {
    const rgba = color.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/,
    );

    if (!rgba) return color;

    let r = parseInt(rgba[1]);
    let g = parseInt(rgba[2]);
    let b = parseInt(rgba[3]);
    const a = rgba[4] !== undefined ? parseFloat(rgba[4]) : 1;

    r = clamp(Math.floor(r * (1 - percent / 100)), 0, 255);
    g = clamp(Math.floor(g * (1 - percent / 100)), 0, 255);
    b = clamp(Math.floor(b * (1 - percent / 100)), 0, 255);

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  return color;
}
