.stepsWrapper {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.step {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.disclaimer {
  padding-top: 40px;
}

@media (max-width: 767px) {
  .stepsWrapper {
    flex-direction: column;
  }
}
