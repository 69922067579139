import React, { forwardRef } from "react";
import {
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
  StyleSheet,
} from "react-native";
import { colors } from "./colors";

export interface TextInputProps extends RNTextInputProps {}

export type TextInput = RNTextInput;

/**
 * `TextInput` is low-level component for keyboard input.
 */
export const TextInput = forwardRef<RNTextInput, TextInputProps>(
  function TextInput(props, ref): JSX.Element {
    const { numberOfLines, style, multiline, ...inputProps } = props;

    const height =
      numberOfLines && numberOfLines > 1
        ? numberOfLines * TEXT_INPUT_HEIGHT_PER_LINE
        : undefined;

    return (
      <RNTextInput
        ref={ref}
        style={[{ height }, styles.input, style]}
        multiline={(numberOfLines && numberOfLines > 1) || multiline}
        placeholderTextColor={colors.text.mutedExtra}
        {...inputProps}
      />
    );
  },
);

export const TEXT_INPUT_FONT_SIZE = 16;
export const TEXT_INPUT_HEIGHT_PER_LINE = 24;

const styles = StyleSheet.create({
  input: {
    fontSize: TEXT_INPUT_FONT_SIZE,
    lineHeight: TEXT_INPUT_HEIGHT_PER_LINE,
    // @ts-expect-error: RN's TextInput does not have these properties but it exists on web environment
    outlineStyle: "none",
  },
});
