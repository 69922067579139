import { colors, useColors } from "@/components/colors";
import { StyleSheet } from "react-native";
import { Icon } from "../icon";
import { PressableHighlight } from "../pressable_highlight";
import { i18n } from "@/helpers/i18n";

interface ScrollToBottomProps {
  onPress: () => void;
}

export function ScrollToBottom(props: ScrollToBottomProps) {
  const { onPress } = props;

  const colors = useColors();

  return (
    <PressableHighlight
      tooltip={i18n.t("chat.scrollToBottom")}
      backgroundColor={colors.background.default}
      onPress={onPress}
      style={styles.button}
    >
      <Icon size={24} color={colors.text.default} name="arrow-down" />
    </PressableHighlight>
  );
}

const styles = StyleSheet.create({
  button: {
    borderWidth: 1,
    borderColor: colors.border.default,
    userSelect: "none",
    borderRadius: 999,
    flexDirection: "row",
    width: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
  },
});
