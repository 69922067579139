:root {
  --black: rgba(0, 0, 0, 1);
  --white: rgba(255, 255, 255, 1);

  --zinc-50: rgba(250, 250, 250, 1);
  --zinc-100: rgba(244, 244, 245, 1);
  --zinc-200: rgba(228, 228, 231, 1);
  --zinc-300: rgba(212, 212, 216, 1);
  --zinc-400: rgba(161, 161, 170, 1);
  --zinc-500: rgba(113, 113, 122, 1);
  --zinc-600: rgba(82, 82, 91, 1);
  --zinc-700: rgba(63, 63, 70, 1);
  --zinc-800: rgba(39, 39, 42, 1);
  --zinc-900: rgba(24, 24, 27, 1);
  --zinc-950: rgba(9, 9, 11, 1);

  --neutral-50: rgba(250, 250, 250, 1);
  --neutral-100: rgba(245, 245, 245, 1);
  --neutral-200: rgba(229, 229, 229, 1);
  --neutral-300: rgba(212, 212, 212, 1);
  --neutral-400: rgba(163, 163, 163, 1);
  --neutral-500: rgba(115, 115, 115, 1);
  --neutral-600: rgba(82, 82, 82, 1);
  --neutral-700: rgba(64, 64, 64, 1);
  --neutral-800: rgba(38, 38, 38, 1);
  --neutral-900: rgba(23, 23, 23, 1);
  --neutral-950: rgba(10, 10, 10, 1);

  --red-50: rgba(254, 242, 242, 1);
  --red-100: rgba(254, 226, 226, 1);
  --red-200: rgba(254, 202, 202, 1);
  --red-300: rgba(252, 165, 165, 1);
  --red-400: rgba(248, 113, 113, 1);
  --red-500: rgba(239, 68, 68, 1);
  --red-600: rgba(220, 38, 38, 1);
  --red-700: rgba(185, 28, 28, 1);
  --red-800: rgba(153, 27, 27, 1);
  --red-900: rgba(127, 29, 29, 1);
  --red-950: rgba(69, 10, 10, 1);

  --orange-50: rgba(255, 247, 237, 1);
  --orange-100: rgba(255, 237, 213, 1);
  --orange-200: rgba(254, 215, 170, 1);
  --orange-300: rgba(253, 186, 116, 1);
  --orange-400: rgba(251, 146, 60, 1);
  --orange-500: rgba(249, 115, 22, 1);
  --orange-600: rgba(234, 88, 12, 1);
  --orange-700: rgba(194, 65, 12, 1);
  --orange-800: rgba(154, 52, 18, 1);
  --orange-900: rgba(124, 45, 18, 1);
  --orange-950: rgba(67, 20, 7, 1);

  --green-50: rgba(240, 253, 244, 1);
  --green-100: rgba(220, 252, 231, 1);
  --green-200: rgba(187, 247, 208, 1);
  --green-300: rgba(134, 239, 172, 1);
  --green-400: rgba(74, 222, 128, 1);
  --green-500: rgba(34, 197, 94, 1);
  --green-600: rgba(22, 163, 74, 1);
  --green-700: rgba(21, 128, 61, 1);
  --green-800: rgba(22, 101, 52, 1);
  --green-900: rgba(20, 83, 45, 1);
  --green-950: #052e16;

  --bermuda-50: rgba(236, 253, 246, 1);
  --bermuda-100: rgba(210, 249, 231, 1);
  --bermuda-200: rgba(169, 241, 213, 1);
  --bermuda-300: rgba(113, 228, 189, 1);
  --bermuda-400: rgba(47, 196, 151, 1);
  --bermuda-500: rgba(20, 181, 138, 1);
  --bermuda-600: rgba(8, 147, 113, 1);
  --bermuda-700: rgba(7, 117, 93, 1);
  --bermuda-800: rgba(8, 93, 74, 1);
  --bermuda-900: rgba(8, 76, 62, 1);
  --bermuda-950: rgba(3, 43, 36, 1);

  --bittersweet-50: #fff3f1;
  --bittersweet-100: #ffe5e1;
  --bittersweet-200: #ffcfc7;
  --bittersweet-300: #ffaea0;
  --bittersweet-400: #ff7962;
  --bittersweet-500: #f8573b;
  --bittersweet-600: #e53a1d;
  --bittersweet-700: #c12d14;
  --bittersweet-800: #a02914;
  --bittersweet-900: #842818;
  --bittersweet-950: #481107;

  --text-default: var(--zinc-800);
  --text-primary: var(--bermuda-700);
  --text-inverted: var(--zinc-50);
  --text-muted: var(--zinc-600);
  --text-muted-extra: var(--zinc-500);
  --text-destructive: var(--red-600);

  --input-text: var(--zinc-800);
  --input-background: var(--zinc-100);
  --input-border: var(--zinc-900);

  --border-default: var(--zinc-300);
  --border-subtle: var(--zinc-100);
  --border-selected: var(--bermuda-700);

  --background-default: var(--white);
  --background-muted: var(--zinc-50);
  --background-muted-extra: var(--zinc-100);
  --background-backdrop: rgba(0, 0, 0, 0.5);
  --background-backdrop-muted: rgba(10, 10, 10, 0.8);
  --background-primary: var(--bermuda-100);

  --button-primary-color: var(--bermuda-500);
  --button-primary-text: var(--white);
  --button-primary-disabled-text: var(--white);
  --button-default-color: rgba(255, 255, 255, 1);
  --button-default-text: var(--zinc-800);
  --button-default-disabled-text: var(--zinc-800);

  --status-active-background: var(--green-200);
  --status-active-text: var(--green-900);
  --status-cancelled-background: var(--orange-200);
  --status-cancelled-text: var(--orange-900);
  --status-expired-background: var(--red-200);
  --status-expired-text: var(--red-900);

  --tooltip-background-color: var(--zinc-950);
  --tooltip-text: var(--white);

  --shadow-color: var(--zinc-300);
  --shadow-opacity: 0.7;
  --shadow-radius: 14px;
  --shadow-offset-width: 0px;
  --shadow-offset-height: 8px;
}
