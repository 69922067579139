import { StyleSheet, View } from "react-native";
import { Icon, IconName } from "./icon";
import { PressableHighlight } from "./pressable_highlight";
import { Text } from "./text";
import { colors } from "./colors";
import React, { forwardRef } from "react";

interface ButtonFlatProps {
  onPress?: () => void;
  icon?: IconName;
  title?: string;
  tooltip?: string;
}

export const ButtonFlat = forwardRef<View, ButtonFlatProps>(
  function ButtonFlat(props, ref) {
    const { onPress, icon, title, tooltip } = props;

    return (
      <PressableHighlight
        style={styles.button}
        onPress={onPress}
        tooltip={tooltip}
        ref={ref}
      >
        {icon && <Icon size={24} name={icon} color={colors.text.default} />}
        {title && (
          <Text
            numberOfLines={1}
            userSelect="none"
            size="sm"
            color={colors.text.default}
          >
            {title}
          </Text>
        )}
      </PressableHighlight>
    );
  },
);

const styles = StyleSheet.create({
  button: {
    padding: 8,
    paddingHorizontal: 16,
    borderRadius: 8,
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
});
