import { Slot } from "expo-router";
import Head from "expo-router/head";

export default function ArticleLayout() {
  return (
    <>
      <Head>
        <title>YLan | Admin Panel</title>
      </Head>
      <Slot />
    </>
  );
}
