import { Header } from "@/components/admin/header";
import { ArticleCard } from "@/components/articles/article_card";
import { ButtonCTA } from "@/components/button_cta";
import { Grid } from "@/components/grid";
import { Container } from "@/components/container";
import { Link } from "expo-router";
import { View } from "react-native";
import { useQuery } from "@/helpers/api";
import { api } from "@/server/api";

export default function ArticlesIndexPage() {
  const articles = useQuery(api.articles.getAll);

  if (!articles) {
    return null;
  }

  return (
    <>
      <Header />
      <Container>
        <Link href="/admin/articles/new" asChild>
          <ButtonCTA title="New article" />
        </Link>
        <View>
          <Grid
            keyExtractor={(article) => article.id}
            data={articles}
            minColumns={2}
            gap={16}
            renderItem={({ item: article }) => (
              <Link key={article.id} href={`/admin/articles/${article.id}`}>
                <ArticleCard article={article} />
              </Link>
            )}
          />
        </View>
      </Container>
    </>
  );
}
