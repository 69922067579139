import DeviceInfo from "react-native-device-info";
import { Dimensions, Platform } from "react-native";
import { getCurrentScreenSize } from "@/hooks/use_screen_size";
import { calendars, locales } from "./i18n";
import { UAParser } from "ua-parser-js";
import { env } from "./env";
import { findOrCreateDeviceId } from "./device";
import { api } from "@/server/api";
import { Logger } from "./logger";

interface AnalyticsEvent {
  event: string;
  properties?: Record<string, any>;
}

const logger = new Logger("Analytics");

class Analytics {
  private registeredProperties: Record<string, any> = {};
  private eventQueue: AnalyticsEvent[] = [];
  private processing = false;
  private batchSize = 10; // Adjust the batch size as needed
  private batchInterval = 5000; // Adjust the interval (in milliseconds) as needed

  constructor() {
    setInterval(() => this.processQueue(), this.batchInterval);
  }

  /** Register a set of super properties, which are included with all events. This will overwrite previous super property values. */
  register(properties: Record<string, any>) {
    Object.assign(this.registeredProperties, properties);
  }

  /** Track an event. This is the most important and frequently used function. */
  async track(event: string, properties?: Record<string, any>) {
    logger.debug(`Track ${event}`);

    if (!env.enableAnalytics) {
      return;
    }

    this.eventQueue.push({ event, properties });

    if (!this.processing) {
      this.processQueue();
    }

    if (env.production) {
      googleAdConversions[event]?.(properties);
    }
  }

  private async processQueue() {
    if (this.processing || this.eventQueue.length === 0) {
      return;
    }

    this.processing = true;

    const deviceId = await findOrCreateDeviceId();

    while (this.eventQueue.length > 0) {
      const batch = this.eventQueue.splice(0, this.batchSize);

      const combinedEvents = await Promise.all(
        batch.map(async ({ event, properties }) => {
          const combinedProperties = {
            ...(await getDeviceProperties()),
            ...(Platform.OS === "web" && (await getWebProperties())),
            ...getCustomProperties(),
            ...this.registeredProperties,
            ...properties,
            device_id: deviceId,
          };

          return { event, properties: combinedProperties };
        }),
      );

      await fetch(`${env.serverUrl}${api.analytics.trackBatch.path}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Device-Id": deviceId,
        },
        body: JSON.stringify({ events: combinedEvents }),
      });
    }

    this.processing = false;
  }
}

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

// Google AdWords Conversion Tracking
// Ensure the script is added in the <head> of the document in +html.tsx
const googleAdConversions: {
  [event: string]: (properties?: Record<string, any>) => void;
} = {
  "Send Message": (properties) => {
    // Send conversion only when it is deliberately sent by the user and not from ad queries
    if (properties?.From === "Composer" || properties?.Ref) {
      window.gtag("event", "conversion", {
        // "Send Message" Conversion Name
        send_to: "AW-16772536959/8AhLCN-MnuoZEP-04r0-",
      });
    }
  },
};

export const analytics = new Analytics();

function getCustomProperties() {
  return {
    Platform: Platform.OS,
    Language: locales[0].languageTag,
    "Screen Size": getCurrentScreenSize(),
    "Time Zone": calendars[0].timeZone,
    "Browser Timestamp": Date.now(),
    "Local Time": new Date().toLocaleTimeString(),
  };
}

async function getWebProperties() {
  const uap = new UAParser();
  const { browser, device } = UAParser(navigator.userAgent);

  const os = await uap.getOS().withClientHints();

  return {
    brand: device.vendor,
    browser_version: browser.version,
    browser: browser.name,
    current_url: window.location.href,
    device: device.type,
    os_version: os.version,
    os: os.name,
    referrer: document.referrer,
  };
}

async function getDeviceProperties() {
  const screenDimensions = Dimensions.get("screen");

  return {
    "User Agent": returnUndefinedInsteadOfUnknown(
      await DeviceInfo.getUserAgent(),
    ),

    brand: returnUndefinedInsteadOfUnknown(DeviceInfo.getBrand()),
    device: returnUndefinedInsteadOfUnknown(await DeviceInfo.getDevice()),
    os_version:
      returnUndefinedInsteadOfUnknown(DeviceInfo.getSystemVersion()) ||
      Platform.Version,
    os: returnUndefinedInsteadOfUnknown(await DeviceInfo.getBaseOs()),
    screen_height: screenDimensions.height,
    screen_width: screenDimensions.width,
  };
}

function returnUndefinedInsteadOfUnknown(value: string): string | undefined {
  return value === "unknown" ? undefined : value;
}
