import { View } from "react-native";
import { isUserMessage, UserMessage } from "./user_message";
import { Composer } from "./composer";
import { isAssistantMessage, AssistantMessage } from "./assistant_message";
import { ScrollToBottom } from "./scroll_to_bottom";
import { MessageList } from "./message_list";
import { useComposerHandlers } from "./use_composer_handlers";
import { FileTempUploaded } from "./types";
import { Container } from "../container";
import { LoadingIndicator } from "../loading_indicator";
import { Text } from "../text";
import { colors } from "../colors";
import { ReactNode } from "react";
import { MessageDoc } from "@/server/db/messages";

interface ChatProps {
  messages: MessageDoc[];
  onSend: (text: string, files: FileTempUploaded[]) => Promise<void>;
  disabled?: boolean;
  placeholder?: ReactNode;
}

export function Chat(props: ChatProps) {
  const { onSend, messages, placeholder, disabled } = props;

  const {
    text,
    files,
    messageQueue,
    handleChangeText,
    handleSend,
    handleAddFiles,
    handleRemoveFile,
  } = useComposerHandlers({ onSend });

  return (
    <Container style={{ paddingHorizontal: 0 }}>
      {messages.length ? (
        <MessageList
          messages={[...messageQueue, ...messages]}
          keyExtractor={(message) =>
            // We want to keep the key of the assistant message the same when it is loading
            // Initially, the assistant message created on front-end for optimistic update has a key of "newAssistantMessage"
            // When the server responds with the real message, we update the key to be the real id
            // But we want to preserve the `Loading` component animation, so we keep the key as "new" when it is still loading/empty
            isAssistantMessage(message) && isAssistantMessageLoading(message)
              ? "new"
              : message.id
          }
          renderMessage={({ message }) => {
            if (isUserMessage(message)) {
              return <UserMessage message={message} />;
            } else if (isAssistantMessage(message)) {
              const loading = isAssistantMessageLoading(message);

              if (!message.error && loading) return <Loading />;

              return <AssistantMessage message={message} />;
            }

            throw new Error("Invalid message role");
          }}
          renderScrollToBottom={ScrollToBottom}
        />
      ) : (
        <View style={{ flex: 1 }}>{placeholder}</View>
      )}
      <View style={{ paddingHorizontal: 16, paddingTop: 8, paddingBottom: 16 }}>
        <Composer
          text={text}
          onChangeText={handleChangeText}
          onAddFiles={handleAddFiles}
          onRemoveFile={handleRemoveFile}
          files={files}
          sendOnEnter={true}
          onSend={handleSend}
          disabled={disabled}
        />
      </View>
    </Container>
  );
}

// When we create a new message, we also create an empty assistant message that will be updated later.
// When it is empty, we show a loading indicator.
function isAssistantMessageLoading(message: MessageDoc) {
  return message.text.trim().length === 0;
}

function Loading() {
  return (
    <View
      // Text is 24px. 20px comes from marginVertical=10px of paragraph in Markdown style
      style={{ flexDirection: "row", gap: 8, alignItems: "center", height: 44 }}
    >
      <LoadingIndicator color={colors.text.muted} />
      <Text color={colors.text.muted}>Đang soạn tin</Text>
    </View>
  );
}
