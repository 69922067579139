import { cloneElement, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colors } from "./colors";
import { Text } from "./text";

interface InputContainerProps {
  children: React.ReactElement;
  label?: string;
  error?: string;
}

export function InputContainer(props: InputContainerProps) {
  const { children, label, error } = props;
  const [focused, setFocused] = useState(false);

  return (
    <View>
      {label && (
        <View>
          <Text bold>{label}</Text>
          <View style={{ height: 4 }} />
        </View>
      )}
      <View style={[styles.container, focused && styles.focused]}>
        {cloneElement(children, {
          onFocus: () => setFocused(true),
          onBlur: () => setFocused(false),
        })}
      </View>
      {error && (
        <View>
          <View style={{ height: 4 }} />
          <Text color={colors.text.destructive} bold>
            {error}
          </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.input.background,
    padding: 8,
    borderWidth: 2,
    borderColor: "transparent",
    borderRadius: 8,
  },
  focused: {
    borderColor: colors.input.border,
  },
});
