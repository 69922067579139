import { useCallback, useEffect, useReducer } from "react";
import { FileTemp, FileTempUploaded, MessageTemp } from "./types";
import { uuid } from "@/helpers/uuid";
import { env } from "@/helpers/env";

interface State {
  text: string;
  files: (FileTemp | FileTempUploaded)[];
  /**
   * Some messages require pre-processing (like image upload) before they are sent to the server.
   * Queue will store messages that are waiting for processing to be finished. The queue will then be processed 1 by 1.
   */
  messageQueue: MessageTemp[];
}

const initialState: State = {
  text: "",
  files: [],
  messageQueue: [],
};

type Action =
  | { type: "SET_TEXT"; text: string }
  | { type: "ADD_FILES"; files: FileTemp[] }
  | { type: "FILE_UPLOADED"; file: FileTemp; url: string }
  | { type: "REMOVE_FILE"; fileUri: string }
  | { type: "SEND"; message: MessageTemp }
  | { type: "PROCESS_QUEUE" };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_TEXT":
      return {
        ...state,
        text: action.text,
      };
    case "ADD_FILES":
      return {
        ...state,
        files: state.files.concat(action.files),
      };
    case "FILE_UPLOADED":
      return {
        ...state,
        // First update the files in the state that user has not sent to the queue yet
        files: state.files.map((file) => {
          // update the file with the url
          if (file.url === action.file.url) {
            return {
              ...action.file,
              url: action.url,
              uploaded: true,
            };
          }

          return file;
        }),

        // Or update the files in the message queue because user already sent the message to the queue
        messageQueue: state.messageQueue.map((message) => {
          if (message.files.some((file) => file.url === action.file.url)) {
            return {
              ...message,
              files: message.files.map((file) => {
                // update the file with the url
                if (file.url === action.file.url) {
                  return {
                    ...action.file,
                    url: action.url,
                    uploaded: true,
                  };
                }

                return file;
              }),
            };
          }

          return message;
        }),
      };
    case "REMOVE_FILE":
      return {
        ...state,
        files: state.files.filter((file) => file.url !== action.fileUri),
      };
    case "SEND": {
      return {
        ...state,
        text: "",
        files: [],
        messageQueue: state.messageQueue.concat(action.message),
      };
    }
    case "PROCESS_QUEUE":
      return {
        ...state,
        messageQueue: state.messageQueue.slice(1),
      };
    default:
      throw new Error("Invalid action type");
  }
}

interface UseComposerHandlersProps {
  /**
   * `onSend` is called only when messages are ready to be sent (after image processing, etc.).
   * Use `messageQueue` in the response to find which messages are waiting for processing to be finished
   */
  onSend: (text: string, files: FileTempUploaded[]) => Promise<void>;
}

export function useComposerHandlers(props: UseComposerHandlersProps) {
  const { onSend } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { text, files, messageQueue } = state;
  const uploadFile = useUploadFile();

  const handleChangeText = useCallback((value: string) => {
    dispatch({ type: "SET_TEXT", text: value });
  }, []);

  const handleSend = useCallback(() => {
    // Create a new message and add it to the queue. This is not sent to the server yet.
    // The message will be sent to the server after all files are uploaded.
    const message: MessageTemp = {
      id: uuid(),
      text,
      files,
      role: "user",
    };

    dispatch({ type: "SEND", message });
  }, [files, text]);

  const handleUploadFile = useCallback(
    async (file: FileTemp) => {
      const url = await uploadFile(file);

      dispatch({ type: "FILE_UPLOADED", file, url });
    },
    [uploadFile],
  );

  const handleAddFiles = useCallback(
    (files: FileTemp[]) => {
      Promise.all(files.map(handleUploadFile));

      dispatch({ type: "ADD_FILES", files });
    },
    [handleUploadFile],
  );

  const handleRemoveFile = useCallback((fileUri: string) => {
    dispatch({ type: "REMOVE_FILE", fileUri });
  }, []);

  useEffect(() => {
    const message = messageQueue[0];

    if (!message) {
      return;
    }

    const { text, files } = message;

    const allFilesUploaded = areAllFilesUploaded(files);

    // If any files are still uploading, don't send the messages yet
    if (!allFilesUploaded) {
      return;
    }

    // Send the message and clear that message from the queue
    onSend(text, files);

    dispatch({ type: "PROCESS_QUEUE" });
  }, [messageQueue, onSend]);

  return {
    text,
    files,
    messageQueue,
    handleChangeText,
    handleSend,
    handleAddFiles,
    handleRemoveFile,
  };
}

function areAllFilesUploaded(
  files: (FileTemp | FileTempUploaded)[],
): files is FileTempUploaded[] {
  return files.every((file) => file.uploaded);
}

function useUploadFile() {
  return useCallback(async (file: FileTemp): Promise<string> => {
    const formData = new FormData();

    const response = await fetch(file.url);
    const blob = await response.blob();

    if (!file.mimeType) {
      throw new Error("File type is missing");
    }

    // Add the blob to FormData, providing a filename or identifier
    // field "files" name must match the server's expectation. check multer upload.array("files")
    formData.append("files", blob, file.name);

    const result = await fetch(`${env.serverUrl}/upload`, {
      method: "POST",
      body: formData, // Send the blobs as FormData
    });

    const { urls } = await result.json();

    return urls[0];
  }, []);
}
