import { colors } from "./colors";
import { Text } from "./text";
import { Container } from "./container";
import { Heading } from "./heading";
import React from "react";
import { StyleSheet, View } from "react-native";

interface SectionProps {
  title: string;
  description?: string;
  children?: React.ReactNode;
}

export function Section(props: SectionProps) {
  const { title, description, children } = props;

  return (
    <View style={styles.section}>
      <Container>
        <View style={styles.sectionHeadingWrapper}>
          <Heading>{title}</Heading>
          {description && (
            <View style={styles.sectionDescription}>
              <Text size="lg" align="center" color={colors.text.muted}>
                {description}
              </Text>
            </View>
          )}
        </View>
      </Container>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  section: {
    paddingTop: 80,
    paddingBottom: 80,
  },
  sectionHeadingWrapper: {
    paddingBottom: 40,
  },
  sectionDescription: {
    paddingTop: 24,
    textAlign: "center",
  },
});
