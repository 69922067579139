import { Container } from "../container";
import { ButtonCTA } from "../button_cta";
import { Section } from "../section";
import { useCallback } from "react";
import { useRouter } from "expo-router";
import { analytics } from "@/helpers/analytics";

interface StartChatSectionProps {}

export function StartChatSection(props: StartChatSectionProps) {
  const router = useRouter();

  const handlePress = useCallback(() => {
    analytics.track("Start Chat Button Pressed", {
      From: "Start Chat Section",
    });
    router.push("/c");
  }, [router]);

  return (
    <Section
      title="Hoàn Toàn Miễn Phí!"
      description="Trải nghiệm dịch vụ tư vấn sức khỏe chất lượng và nhanh chóng hoàn toàn miễn phí. Bắt đầu trò chuyện ngay để chủ động kiểm soát hành trình sức khỏe của bạn."
    >
      <Container style={{ alignItems: "center" }}>
        <ButtonCTA
          color="primary"
          onPress={handlePress}
          title="Trò chuyện ngay"
          shape="pill"
        />
      </Container>
    </Section>
  );
}
