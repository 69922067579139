import RNMarkdown from "react-native-markdown-display";
import { colors } from "../colors";
import { StyleSheet } from "react-native";

interface MarkdownProps {
  children: string;
}
export function Markdown(props: MarkdownProps) {
  const { children } = props;

  return <RNMarkdown style={styles}>{children}</RNMarkdown>;
}

const styles = StyleSheet.create({
  heading1: {
    fontSize: 32,
    lineHeight: 40,
    fontWeight: 700,
    paddingTop: 16,
    paddingBottom: 8,
  },
  heading2: {
    fontSize: 24,
    lineHeight: 32,
    fontWeight: 600,
    paddingTop: 16,
    paddingBottom: 8,
  },
  heading3: {
    fontSize: 18,
    lineHeight: 26,
    fontWeight: 600,
    paddingTop: 16,
    paddingBottom: 8,
  },
  heading4: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 500,
  },
  heading5: {
    fontSize: 13,
  },
  heading6: {
    fontSize: 11,
  },
  list_item: {
    paddingVertical: 4,
  },
  strong: {
    fontWeight: 500,
  },
  body: {
    color: colors.text.default,
    fontSize: 16,
    lineHeight: 24,
  },
});
