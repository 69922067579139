import { ScrollViewStyleReset } from "expo-router/html";
import { ChatPage } from "@/components/chat/chat_page";
import { Slot } from "expo-router";
import { View } from "react-native";
import { SideBarProvider } from "@/components/app/side_bar_provider";
import { Header } from "@/components/chat/header";
import { SideBarMenu } from "@/components/app/side_bar_menu";

export default function ChatLayout() {
  return (
    <View style={{ flex: 1 }}>
      {/* This is unused as seen in `/c/[id].tsx` and `/c/index.tsx`. Main purpose is to have proper pages generated by expo */}
      {/* Having all of the code in a single component helps avoid page refreshes due to navigation */}
      <Slot />
      <ScrollViewStyleReset />
      <SideBarProvider sidebar={<SideBarMenu />}>
        <Header />
        <ChatPage />
      </SideBarProvider>
    </View>
  );
}
