.heading {
  margin: 0;
  text-align: center;
  font-weight: 600;
  color: var(--text-default);
}

.primary {
  color: var(--text-primary);
}

.default {
  color: var(--text-default);
}

@media (max-width: 767px) {
  .heading {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .heading {
    font-size: 48px;
  }
}
