import { ArticleDoc } from "@/server/db/articles";
import { Image } from "expo-image";
import { View } from "react-native";
import { Text } from "../text";
import { colors } from "../colors";

interface ArticleCardProps {
  article: ArticleDoc;
}

export function ArticleCard(props: ArticleCardProps) {
  const { article } = props;

  return (
    <View>
      <Image source={article.imageUrl} style={{ height: 200 }} />
      <View style={{ height: 4 }} />
      <Text bold size="lg">
        {article.title}
      </Text>
      <View style={{ height: 4 }} />
      <Text color={colors.text.muted}>{article.description}</Text>
    </View>
  );
}
