import { env } from "./env";

export class Logger {
  namespace: string;

  constructor(namespace: string) {
    this.namespace = namespace;
  }

  log(level: "debug" | "warn" | "error" | "info", message: string): void {
    if (env.production) {
      return;
    }
    console[level](`${this.namespace}]: ${message}`);
  }

  debug(message: string): void {
    this.log("debug", message);
  }

  error(error: Error): void {
    this.log("error", error.message);
    console.error(error.stack);
  }

  warn(message: string): void {
    this.log("warn", message);
  }

  info(message: string): void {
    this.log("info", message);
  }
}
