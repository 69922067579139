import { Image } from "expo-image";
import { View } from "react-native";
import { Markdown } from "./markdown";
import { ArticleTemp } from "../admin/articles/types";

interface ArticleViewProps {
  article: ArticleTemp;
}

export function ArticleView(props: ArticleViewProps) {
  const { article } = props;

  return (
    <View style={{ flex: 1 }}>
      <Image source={article.imageUrl} style={{ width: "100%", height: 400 }} />
      <Markdown>{article.content}</Markdown>
    </View>
  );
}
