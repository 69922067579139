import { useMemo } from "react";
import { Dimensions, useWindowDimensions } from "react-native";

const breakpoints = {
  // small (mobile)
  small: 768,
  // medium (tablet and laptop)
  large: 1728,
  // large (external monitor)
};

export function useScreenSize() {
  const dimensions = useWindowDimensions();

  const size = useMemo(() => calculateScreenSize(dimensions), [dimensions]);

  return size;
}

export function calculateScreenSize(dimensions: { width: number }) {
  const small = dimensions.width < breakpoints.small;
  const medium =
    dimensions.width >= breakpoints.small &&
    dimensions.width < breakpoints.large;
  const large = dimensions.width >= breakpoints.large;

  return {
    /** For most mobile phones */
    small,
    /** For tablet and laptop displays ranging from iPad Mini to 16' MacBook Pro */
    medium,
    /** For most external monitors */
    large,
  };
}

export function getCurrentScreenSize() {
  const screenSize = calculateScreenSize(Dimensions.get("screen"));

  if (screenSize.small) {
    return "small";
  } else if (screenSize.medium) {
    return "medium";
  }

  return "large";
}
