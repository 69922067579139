import { Container } from "@/components/container";
import { Paragraph } from "@/components/paragraph";
import { Section } from "@/components/section";
import { Text } from "@/components/text";

export default function Terms() {
  return (
    <div>
      <Section title="Điều Khoản Sử Dụng">
        <Container>
          <Text>
            Bằng cách sử dụng các dịch vụ về sức khỏe của YLan, bạn đồng ý với
            các điều khoản sau:
          </Text>
          <Paragraph>
            <Text bold>Chỉ Cung Cấp Thông Tin</Text>: YLan cung cấp thông tin
            sức khỏe tổng quát thông qua các dịch vụ của mình, và thông tin này
            không nên được coi là thay thế cho lời khuyên, chẩn đoán, hoặc điều
            trị y tế chuyên nghiệp. Luôn tham khảo ý kiến của chuyên gia y tế
            đối với các vấn đề sức khỏe.
          </Paragraph>
          <Paragraph>
            <Text bold>Không Dùng Trong Trường Hợp Khẩn Cấp</Text>: Dịch vụ của
            YLan không được thiết kế cho các trường hợp khẩn cấp. Nếu bạn đang
            gặp tình huống y tế khẩn cấp, hãy gọi số khẩn cấp tại địa phương
            hoặc đến phòng cấp cứu gần nhất ngay lập tức.
          </Paragraph>
          <Paragraph>
            <Text bold>Quyền Riêng Tư và Bảo Mật Dữ Liệu</Text>: Chúng tôi ưu
            tiên bảo mật và quyền riêng tư của dữ liệu của bạn. Thông tin chia
            sẻ với YLan được giữ bảo mật và xử lý theo các quy định về quyền
            riêng tư áp dụng. Tuy nhiên, tránh chia sẻ thông tin cá nhân nhạy
            cảm.
          </Paragraph>
          <Paragraph>
            <Text bold>Giới Hạn Trách Nhiệm</Text>: Các dịch vụ của YLan được
            cung cấp “nguyên trạng” và không có bất kỳ bảo đảm nào. Chúng tôi
            không chịu trách nhiệm đối với bất kỳ quyết định hoặc hành động nào
            dựa trên thông tin từ dịch vụ của YLan.
          </Paragraph>
          <Paragraph>
            <Text bold>Thỏa Thuận Sử Dụng</Text>: Bằng cách sử dụng dịch vụ của
            YLan, bạn đồng ý không sử dụng sai mục đích, bao gồm nhưng không
            giới hạn ở việc: tải lên nội dung có hại, hành vi lạm dụng, hoặc vi
            phạm bất kỳ luật pháp nào áp dụng.
          </Paragraph>
          <Paragraph>
            Bằng việc tiếp tục sử dụng dịch vụ của YLan, bạn xác nhận rằng bạn
            đã đọc và đồng ý với các điều khoản này.
          </Paragraph>
        </Container>
      </Section>
    </div>
  );
}

// Terms of Use

// By using YLan’s health AI services, you agree to the following terms:

// Information Only: YLan provides general health information through its services, which should not be considered a substitute for professional medical advice, diagnosis, or treatment. Always consult a healthcare provider for medical concerns.

// Non-Emergency Use: YLan’s services are not intended for emergency use. If you are experiencing a medical emergency, call your local emergency number or visit the nearest emergency room immediately.

// Privacy and Data Security: We prioritize the privacy and security of your data. Information shared with YLan is kept confidential and handled according to applicable privacy laws. However, avoid sharing sensitive personal information.

// Limitations of Liability: YLan’s services are provided “as is” without warranties of any kind. We are not liable for any decisions or actions taken based on information provided by YLan’s services.

// Usage Agreement: By using YLan’s services, you agree not to misuse them, including but not limited to: uploading harmful content, engaging in abusive behavior, or violating any applicable laws.

// By continuing to use YLan’s services, you acknowledge that you have read and agree to these terms.
