import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import { Slot } from "expo-router";
import Head from "expo-router/head";
import { View } from "react-native";

export default function ArticleLayout() {
  return (
    <>
      <Head>
        <title>YLan | Trợ Lý Sức Khỏe Trực Tuyến Đáng Tin Cậy</title>
      </Head>
      <Header />
      <View style={{ height: 120 }} />
      <Slot />
      <View style={{ height: 80 }} />
      <Footer />
    </>
  );
}
