import { Preview } from "@/components/admin/articles/preview";
import { colors } from "@/components/colors";
import { useLocalSearchParams, useRouter } from "expo-router";
import { View } from "react-native";
import { useCallback } from "react";
import { ButtonCTA } from "@/components/button_cta";
import { Text } from "@/components/text";
import { useMutation, useQuery } from "@/helpers/api";
import { api } from "@/server/api";

type ArticlePageParams = {
  articleId: string;
};

export default function ArticlePage() {
  const { articleId } = useLocalSearchParams<ArticlePageParams>();

  const router = useRouter();
  const remove = useMutation(api.articles.remove);
  const article = useQuery(api.articles.getById, { id: articleId });

  const handleRemove = useCallback(async () => {
    await remove({ articleId });
    router.replace("/admin/articles");
  }, [articleId, remove, router]);

  if (!article) {
    return null;
  }

  return (
    <>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          backgroundColor: colors.background.default,
        }}
      >
        <View
          style={{
            width: 480,
            padding: 16,
            borderRightWidth: 1,
            borderColor: colors.border.default,
            backgroundColor: colors.background.default,
          }}
        >
          <Text>Articles are currently not editable</Text>
        </View>
        <Preview article={article} />
      </View>
      <ButtonCTA color="destructive" title="Remove" onPress={handleRemove} />
    </>
  );
}
