import { useColors } from "@/components/colors";
import { Animated, Platform, StyleSheet, View } from "react-native";
import { Icon } from "../icon";
import { useCallback, useEffect, useRef, useState } from "react";
import { FileMenu, FileMenuProps } from "./file_menu";
import { Popover } from "../popover";
import { PressableHighlight } from "../pressable_highlight";
import { i18n } from "@/helpers/i18n";
import { analytics } from "@/helpers/analytics";

interface AttachFileButtonProps extends FileMenuProps {}

export function AttachFileButton(props: AttachFileButtonProps) {
  const {
    onAddFileImagesFromCamera,
    onAddFileImagesFromGallery,
    onAddFilePDF,
  } = props;
  const [visible, setVisible] = useState(false);

  const rotateAnimation = useRef(new Animated.Value(visible ? 1 : 0)).current;
  const colors = useColors();

  const handlePress = useCallback(() => {
    analytics.track("Attach File Button Pressed");

    if (Platform.OS === "web") {
      // On Desktop Web, it will show Explorer to select image or PDF
      // On iOS Web, it will show menu to select image from camera, gallery or files
      onAddFileImagesFromGallery();
      return;
    }
  }, [onAddFileImagesFromGallery]);

  const handleAddFileImagesFromCamera = useCallback(() => {
    onAddFileImagesFromCamera();
    setVisible(false);
  }, [onAddFileImagesFromCamera]);

  const handleAddFileImagesFromGallery = useCallback(() => {
    onAddFileImagesFromGallery();
    setVisible(false);
  }, [onAddFileImagesFromGallery]);

  const handleAddFilePDF = useCallback(() => {
    onAddFilePDF();
    setVisible(false);
  }, [onAddFilePDF]);

  useEffect(() => {
    Animated.spring(rotateAnimation, {
      toValue: visible ? 1 : 0,
      useNativeDriver: true,
    }).start();
  }, [visible, rotateAnimation]);

  return (
    <View>
      <Popover
        visible={visible}
        onRequestClose={() => setVisible(false)}
        position="topLeft"
        content={
          <FileMenu
            onAddFileImagesFromCamera={handleAddFileImagesFromCamera}
            onAddFileImagesFromGallery={handleAddFileImagesFromGallery}
            onAddFilePDF={handleAddFilePDF}
          />
        }
      >
        <PressableHighlight
          backgroundColor={colors.input.background}
          tooltip={i18n.t("chat.attachFile")}
          onPress={handlePress}
          style={styles.button}
        >
          <Animated.View
            style={{
              transform: [
                {
                  rotate: rotateAnimation.interpolate({
                    inputRange: [0, 1],
                    outputRange: ["0deg", "45deg"],
                  }),
                },
              ],
            }}
          >
            <Icon size={24} color={colors.text.default} name="plus" />
          </Animated.View>
        </PressableHighlight>
      </Popover>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    userSelect: "none",
    borderRadius: 999,
    flexDirection: "row",
    width: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
  },
});
