import { Stack } from "expo-router";
import Head from "expo-router/head";
import { ScrollViewStyleReset } from "expo-router/html";

export default function ArticleLayout() {
  return (
    <>
      <Head>
        <title>YLan | Articles</title>
      </Head>
      <ScrollViewStyleReset />
      <Stack
        screenOptions={{
          headerShown: false,
        }}
      />
    </>
  );
}
