import styles from "./gradient_blur.module.css";

export function GradientBlur() {
  return (
    <>
      <div className={styles.gradientBlurTop}>
        <div className={styles.gradientBlurTopInner} />
      </div>
      <div className={styles.gradientBlurBottom}>
        <div className={styles.gradientBlurBottomInner} />
      </div>
    </>
  );
}
