.wrapper {
  position: relative;
  z-index: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headingWrapper {
  text-align: center;
  padding-bottom: 60px;
}

.composerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.composerInnerWrapper {
  max-width: 600px;
  width: 100%;
}

@media (max-width: 767px) {
  .composerWrapper {
    position: fixed;
    left: 16px;
    right: 16px;
    bottom: 16px;
    width: initial;
  }

  .headingWrapper {
    padding-bottom: 40px;
  }
}
