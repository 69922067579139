import { StyleSheet, View } from "react-native";
import { ButtonFlat } from "../button_flat";
import { useCallback } from "react";
import { useSideBar } from "../app/side_bar_provider";
import { useDeviceId } from "@/helpers/device";
import { useQuery } from "@/helpers/api";
import { api } from "@/server/api";

export function Header() {
  const { open } = useSideBar();
  const deviceId = useDeviceId();
  const threads = useQuery(
    api.threads.getAll,
    deviceId ? { deviceId } : "skip",
  );

  const handleOpenSideBar = useCallback(() => {
    open();
  }, [open]);

  if (!threads || threads.length <= 1) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <ButtonFlat
          icon="layout-sidebar-left-expand"
          onPress={handleOpenSideBar}
        />
        {/* <ButtonFlat icon="edit" onPress={handleNewThread} /> */}
      </View>
      <View style={styles.right}>
        {/* <ButtonFlat title="Share" onPress={handleNewThread} /> */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 80,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
  },
  left: {
    flex: 1,
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  right: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
});
