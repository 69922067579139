import { KeyboardEvent, useCallback, useRef } from "react";
import {
  StyleSheet,
  Platform,
  LayoutChangeEvent,
  View,
  Text,
} from "react-native";
import { colors } from "../colors";
import { i18n } from "@/helpers/i18n";
import {
  TextInput as CustomTextInput,
  TEXT_INPUT_FONT_SIZE,
  TEXT_INPUT_HEIGHT_PER_LINE,
} from "../text_input";

export interface TextInputProps {
  value?: string;
  onChangeText?: (value: string) => void;
  textInputRef: React.RefObject<CustomTextInput>;
  onSubmitEditing: () => void;
  onFocus: () => void;
  onBlur: () => void;
  sendOnEnter?: boolean;
}

export interface ContentSize {
  width: number;
  height: number;
}

/** Allow only 5 lines of text in the TextInput */
const MAX_LINES = 5;

export function TextInput(props: TextInputProps) {
  const {
    sendOnEnter,
    textInputRef,
    value,
    onChangeText,
    onSubmitEditing,
    onFocus,
    onBlur,
  } = props;
  const heightRef = useRef(TEXT_INPUT_HEIGHT_PER_LINE);
  const containerRef = useRef<View>(null);
  const handleKeyPress = useCallback(
    /**
     * The event differs depending whether it is coming from web or native environment.
     * We assign appropriate type to the event depending on the platform.
     */
    (e: unknown) => {
      if (Platform.OS === "web") {
        const webEvent = e as KeyboardEvent<HTMLInputElement>;

        const newLine = webEvent.key === "Enter" && webEvent.shiftKey;

        if (!newLine && sendOnEnter && webEvent.key === "Enter") {
          webEvent.preventDefault();
          onSubmitEditing();
        }
      }
    },
    [sendOnEnter, onSubmitEditing],
  );

  const handleHiddenTextLayout = useCallback((e: LayoutChangeEvent) => {
    const value = e.nativeEvent.layout;

    const linesCount = Math.min(
      Math.ceil(value.height / TEXT_INPUT_HEIGHT_PER_LINE),
      MAX_LINES,
    );

    const height = TEXT_INPUT_HEIGHT_PER_LINE * linesCount;

    if (Platform.OS === "web") {
      // @ts-expect-error: this is a div element in web
      const divEl = containerRef.current as HTMLDivElement;
      divEl.style.height = `${height}px`;
    } else {
      containerRef.current?.setNativeProps({ style: height });
    }
  }, []);

  return (
    <View style={{ minHeight: 32, justifyContent: "center" }}>
      <View
        ref={containerRef}
        style={[styles.container, { height: heightRef.current }]}
      >
        <CustomTextInput
          ref={textInputRef}
          placeholder={i18n.t("chat.inputPlaceholder")}
          placeholderTextColor={colors.text.mutedExtra}
          onChangeText={onChangeText}
          onSubmitEditing={onSubmitEditing}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          blurOnSubmit={false}
          onKeyPress={handleKeyPress}
          multiline
          style={styles.input}
        />
        {/* This is a hidden text that is used to measure the height of the text of TextInput.
         * We can't rely on RN's TextInput `onContentSizeChange` alone as it does not account for when content size height is decreased.
         */}
        <Text style={styles.hiddenText} onLayout={handleHiddenTextLayout}>
          {/* When user presses 'Return' it creates a new line but the height
           * of the text doesn't change. This is a hack to make it account for height
           * of the new line.
           */}
          {value?.charAt(value.length - 1) === "\n" || value === ""
            ? value + "."
            : value}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  input: {
    flex: 1,
  },
  hiddenText: {
    position: "absolute",
    opacity: 0,
    fontSize: TEXT_INPUT_FONT_SIZE,
    lineHeight: TEXT_INPUT_HEIGHT_PER_LINE,
  },
});
