import styles from "./how_it_works_section.module.css";
import { Text } from "../text";
import { Section } from "../section";
import { Container } from "../container";

export function HowItWorksSection() {
  return (
    <Section
      title="Giúp sức khỏe của bạn"
      description="Trợ lý sức khỏe sử dụng cơ sở dữ liệu y khoa đáng tin cậy để phân tích câu hỏi và cung cấp thông tin y tế chính xác, hỗ trợ bạn trong việc chăm sóc sức khỏe hiệu quả."
    >
      <Container>
        <div className={styles.stepsWrapper}>
          <Step
            title="Cơ sở dữ liệu uy tín"
            description="Trợ lý được đào tạo từ các tài liệu y khoa, nghiên cứu và hướng dẫn được chứng nhận bởi các tổ chức y tế quốc tế."
          />
          <Step
            title="Đội ngũ chuyên gia kiểm duyệt"
            description="Các câu trả lời quan trọng đều được kiểm tra bởi bác sĩ và chuyên gia y tế trước khi triển khai."
          />
          <Step
            title="Hỗ trợ chăm sóc cá nhân"
            description="Đề xuất các bước chăm sóc sức khỏe cơ bản hoặc giải pháp tạm thời trước khi gặp bác sĩ."
          />
        </div>
        <div className={styles.disclaimer}>
          <Text size="sm">
            <Text size="sm" bold>
              Lưu ý
            </Text>
            : Trợ lý thông minh không thay thế bác sĩ mà là công cụ hỗ trợ để
            bạn đưa ra quyết định sáng suốt và hiệu quả hơn về sức khỏe!
          </Text>
        </div>
      </Container>
    </Section>
  );
}

interface StepProps {
  title: string;
  description: string;
}

function Step(props: StepProps) {
  const { title, description } = props;

  return (
    <div className={styles.step}>
      {/* <span className={styles.stepNumber}>{step}</span> */}
      <Text size="lg" bold>
        {title}
      </Text>
      <Text>{description}</Text>
    </div>
  );
}
