import Svg, { Path } from "react-native-svg";

export function Logo() {
  return (
    <Svg width="110" height="40" viewBox="0 0 110 40" fill="none">
      <Path
        d="M50.3461 17.7213L55.6422 9.36163H60.1696V9.53508L52.2253 21.2503V29.6101H48.4667V21.2503L40.8069 9.53508V9.36163H45.2774L50.3461 17.7213Z"
        fill="#3CC5A1"
      />
      <Path
        d="M65.5799 9.36163V26.0811H75.8309V29.6101H61.8214V9.36163H65.5799Z"
        fill="#3CC5A1"
      />
      <Path
        d="M81.497 22.4652C81.497 25.1844 83.3477 26.8042 85.6543 26.8042C91.1213 26.8042 91.1213 18.1552 85.6543 18.1552C83.3477 18.1552 81.497 19.7462 81.497 22.4652ZM89.9254 15.3494H93.2569V29.6101H89.9822L89.8115 27.5273C89.0142 29.2052 86.8217 30.0151 85.2554 30.044C81.0982 30.073 78.0231 27.4696 78.0231 22.4652C78.0231 17.5476 81.2406 14.9734 85.3411 15.0021C87.2203 15.0021 89.0142 15.899 89.8115 17.3163L89.9254 15.3494Z"
        fill="#3CC5A1"
      />
      <Path
        d="M106.526 29.6101V22.1471C106.526 19.9776 105.359 18.3288 103.138 18.3288C101.002 18.3288 99.5498 20.1512 99.5498 22.3207V29.6101H96.1045V15.3204H99.2081L99.436 17.2584C100.86 15.8412 102.283 15.1179 104.049 15.1179C107.352 15.1179 110 17.6344 110 22.1181V29.6101H106.526Z"
        fill="#3CC5A1"
      />
      <Path
        d="M29.6456 11.2258L27.3696 11.387C26.9427 11.4172 26.5725 11.0926 26.5426 10.662C26.5127 10.2312 26.8343 9.85755 27.261 9.82735L29.5373 9.66602C29.964 9.63582 30.3342 9.96053 30.3642 10.3912C30.3941 10.8218 30.0725 11.1954 29.6456 11.2258ZM25.0218 5.10926L24.8621 7.40628C24.832 7.83692 24.4618 8.16163 24.0351 8.13129C23.6083 8.10108 23.2866 7.72746 23.3165 7.29681L23.4764 4.9998C23.5063 4.569 23.8765 4.24443 24.3034 4.27464C24.7301 4.30499 25.0517 4.67847 25.0218 5.10926ZM33.61 0.126131C25.9719 0.45335 20.1005 4.09475 18.8559 8.92804C18.5882 9.9676 18.366 11.7174 19.1282 14.0809C19.145 14.1329 19.1672 14.1837 19.1934 14.2315L19.2688 14.3686L28.8076 4.74226C29.1088 4.43832 29.6018 4.43832 29.9031 4.74226C30.2043 5.04635 30.2043 5.54388 29.9031 5.84782L20.365 15.4735L20.4979 15.548C20.5453 15.5745 20.5955 15.5968 20.6471 15.6137C22.989 16.3829 24.723 16.1587 25.7532 15.8885C30.5426 14.6325 34.1507 8.70735 34.475 0.999059C34.4957 0.507273 34.0973 0.10521 33.61 0.126131Z"
        fill="#3CC5A1"
      />
      <Path
        d="M17.7548 15.6517C17.6755 15.4489 17.5918 15.2477 17.4989 15.05C17.4981 15.0481 17.4972 15.046 17.4963 15.0441L17.4962 15.0445C16.9555 13.8945 16.1839 12.8341 15.176 11.9163C13.1524 10.0734 10.498 9.0968 7.68218 8.95107C7.36485 8.93459 7.0998 9.17591 7.11788 9.46484C7.27792 12.0292 8.35039 14.4465 10.374 16.2893C12.0181 17.7867 14.0786 18.7121 16.3039 19.0846V40H18.7232C18.7232 33.1268 18.7232 26.2537 18.7232 19.3805C18.661 18.8887 18.5695 18.3604 18.4373 17.8033C18.249 17.0085 18.0101 16.2896 17.7548 15.6517Z"
        fill="#3CC5A1"
      />
      <Path
        d="M20.399 0.452737C19.2618 0.830637 18.3023 1.40257 17.5004 2.05391C16.6982 1.40257 15.7389 0.830637 14.6016 0.452737C12.1931 -0.347263 9.57209 -0.0835428 7.022 1.21576C4.84732 2.32382 2.89727 4.13524 1.67197 6.18578C0.432546 8.25988 -0.758014 11.7918 0.608761 16.8402C1.72542 20.9649 4.62794 24.5302 9.0025 27.1511C11.0481 28.3767 13.0165 29.1577 14.3273 29.6022V27.0126C10.9667 25.7492 4.67213 22.5862 2.94226 16.1968C1.80115 11.9822 2.74814 9.11204 3.7435 7.44647C6.2499 3.25229 10.848 1.77532 13.8451 2.77156C15.5319 3.33185 16.7175 4.49664 17.5004 5.57185C18.2463 4.54732 19.3591 3.44279 20.9217 2.85598C22.1411 1.81568 23.6023 0.928318 25.2519 0.225703C23.5965 -0.136581 21.9535 -0.0636538 20.399 0.452737Z"
        fill="#3CC5A1"
      />
      <Path
        d="M32.5753 12.7471C32.5552 13.7775 32.4026 14.9252 32.0584 16.1968C30.3285 22.5862 24.0339 25.7492 20.6733 27.0126V29.6022C21.984 29.1577 23.9526 28.3767 25.9982 27.1511C30.3726 24.5302 33.2752 20.9649 34.3919 16.8402C35.2615 13.6281 35.0945 11.032 34.5396 9.03278C34.0009 10.4021 33.34 11.6488 32.5753 12.7471Z"
        fill="#3CC5A1"
      />
    </Svg>
  );
}
