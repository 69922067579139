.featuresWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 56px;
  align-items: center;
}

.featureContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.featureImage {
  width: 104px;
  height: 104px;
  display: flex;
}

.suggestionsWrapper {
  display: flex;
  flex-direction: column;
}

.suggestion {
  padding: 8px;
  margin: 0 -8px;
  border-radius: 8px;
  user-select: none;
  font-size: 14px;
  color: var(--text-primary);
}

.suggestion:hover {
  background-color: var(--background-muted);
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 768px) {
  .featureWrapper {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }
}
