import { useCallback, useEffect, useState } from "react";
import { env } from "./env";
import { findOrCreateDeviceId } from "./device";

export const apiClient = {
  query: async <T extends ApiQueryEndpoint>(
    endpoint: T | string,
    args?: T["_args"] | "skip",
  ): Promise<T["_result"]> => {
    let path = typeof endpoint === "string" ? endpoint : endpoint.path;

    if (args) {
      const params = new URLSearchParams(args);
      path += `?${params.toString()}`;
    }

    const response = await fetch(env.serverUrl + path, {
      headers: { "Device-Id": await findOrCreateDeviceId() },
    });

    const data = await response.json();

    return data;
  },
  mutation: async <T extends ApiMutationEndpoint>(
    endpoint: T | string,
    args: T["_args"],
  ): Promise<T["_result"]> => {
    const path = typeof endpoint === "string" ? endpoint : endpoint.path;

    const response = await fetch(env.serverUrl + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Device-Id": await findOrCreateDeviceId(),
      },
      body: JSON.stringify(args),
    });

    const data = (await response.json()) as T["_result"];

    return data;
  },
};

type ApiQueryEndpoint = {
  path: string;
  _args?: any;
  _result: any;
};

export function useQuery<T extends ApiQueryEndpoint>(
  endpoint: T,
  args?: T["_args"] | "skip",
): T["_result"] | undefined {
  const [data, setData] = useState<T["_result"] | undefined>();

  // stringify the args to compare them and avoid infinite loops
  const argsString =
    args === "skip" ? "skip" : args ? JSON.stringify(args) : undefined;

  useEffect(() => {
    if (argsString === "skip") {
      return;
    }

    apiClient
      .query(endpoint.path, argsString ? JSON.parse(argsString) : undefined)
      .then(setData);
  }, [endpoint.path, argsString]);

  return data;
}

type ApiMutationEndpoint = {
  path: string;
  _args?: any;
  _result: any;
};

export function useMutation<T extends ApiMutationEndpoint>(
  endpoint: T,
): (args: T["_args"]) => Promise<T["_result"]> {
  return useCallback(
    async (args: T["_args"]) => {
      return apiClient.mutation(endpoint.path, args);
    },
    [endpoint.path],
  );
}

type ApiSubscriptionEndpoint = {
  path: string;
  _args?: any;
  _result: any;
};

export function useSubscription<T extends ApiSubscriptionEndpoint>(
  endpoint: T,
  args?: T["_args"] | "skip",
): T["_result"] | undefined {
  const [data, setData] = useState<T["_result"] | undefined>();

  // stringify the args to compare them and avoid infinite loops
  const argsString =
    args === "skip" ? "skip" : args ? JSON.stringify(args) : undefined;

  useEffect(() => {
    if (argsString === "skip") {
      return;
    }

    let path = endpoint.path;

    if (argsString) {
      const args = JSON.parse(argsString);

      const params = new URLSearchParams(args);
      path += `?${params.toString()}`;
    }

    const eventSource = new EventSource(env.serverUrl + path);

    eventSource.onmessage = (event) => {
      setData(JSON.parse(event.data));
    };

    return () => {
      eventSource.close();
    };
  }, [endpoint.path, argsString]);

  return data;
}
