import { useGlobalSearchParams, useRouter } from "expo-router";
import { analytics } from "@/helpers/analytics";
import { useCallback, useEffect, useState } from "react";
import { Chat } from "@/components/chat/chat";
import { FileTemp, FileTempUploaded } from "@/components/chat/types";
import { findOrCreateDeviceId, useDeviceId } from "@/helpers/device";
import { View } from "react-native";
import { Heading } from "../heading";
import { apiClient, useMutation, useSubscription } from "@/helpers/api";
import { api } from "@/server/api";
import { MessageDoc } from "@/server/db/messages";

type IndexChatPageParams = {
  q?: string;
  ref?: string;
  threadId?: string;
};

export function ChatPage() {
  const createThread = useMutation(api.threads.create);
  const createMessage = useMutation(api.messages.create);
  const router = useRouter();
  const { threadId, q, ref } = useGlobalSearchParams<IndexChatPageParams>();
  const [messages, setMessages] = useState<MessageDoc[]>(
    q ? [assistantMessageMock(), userMessageMock(q)] : [],
  );
  const deviceId = useDeviceId();

  // Fetch and cache `messages` on mount or when `threadId` searchParam changes.
  // `threadId` changes from either `createThreadFromQSearchParam` or from creating a new thread.
  useEffect(() => {
    async function mountOrIdSearchParamChange() {
      if (!threadId) return;

      const deviceId = await findOrCreateDeviceId();
      const messages = await apiClient.query(api.messages.getAllByThreadId, {
        threadId,
        deviceId,
      });

      setMessages(messages);

      // if thread was newly created from the query search param, immediately subscribe to answer on the user message
      const lastMessage = messages[0];
      if (lastMessage.role !== "assistant") {
        throw new Error("Expected an assistant message");
      }

      const finished = !!lastMessage.finishReason;
      if (!finished) setSubscribedMessageId(lastMessage.id);
    }

    mountOrIdSearchParamChange();
  }, [threadId]);

  // If the user came with `q` search parameter, automatically create a thread with the term.
  useEffect(() => {
    if (!q) return;

    async function createThreadFromQSearchParam(text: string) {
      const deviceId = await findOrCreateDeviceId();
      const thread = await createThread({ text, deviceId });

      analytics.track("Send Message", {
        "Thread ID": thread.id,
        Text: text,
        From: "Query",
        Ref: ref,
      });

      router.replace(`/c/${thread.id}`);
    }

    createThreadFromQSearchParam(q);
  }, [q, ref, createThread, router]);

  // Reactive assistant message will be continuously updated from server
  const [subscribedMessageId, setSubscribedMessageId] = useState<string>();

  const answer = useSubscription(
    api.messages.getAnswer,
    subscribedMessageId && threadId && deviceId
      ? { id: subscribedMessageId, threadId, deviceId }
      : "skip",
  );

  if (answer && answer.role !== "assistant") {
    throw new Error("Expected an assistant message");
  }

  // Update the reactive assistant message in the `messages` cache
  useEffect(() => {
    if (!answer) return;

    setMessages((prevMessages) =>
      prevMessages.map((m) => (m.id === answer.id ? answer : m)),
    );

    // Stop updating the assistant message if it has been finished
    if (answer.finishReason) setSubscribedMessageId(undefined);
  }, [answer]);

  const handleSend = useCallback(
    async (text: string, files: FileTempUploaded[]) => {
      const deviceId = await findOrCreateDeviceId();

      analytics.track("Send Message", {
        "Thead ID": threadId,
        Text: text,
        Files: files.map((f) => f.url),
        From: "Composer",
        Ref: ref,
      });

      // Create a new thread if the user is not in a thread
      if (!threadId) {
        setMessages([assistantMessageMock(), userMessageMock(text, files)]);

        const newThread = await createThread({ text, files, deviceId });

        router.replace(`/c/${newThread.id}`);
        return;
      }

      // Optimistically add the user message and accompanying assistant message to the list
      // By adding those messages to the `messages` cache
      setMessages((prevMessages) => [
        assistantMessageMock(),
        userMessageMock(text),
        ...prevMessages,
      ]);

      // Send the message to the server, it assumes that the server will create both user and assistant message
      await createMessage({ text, files, threadId, deviceId });

      // Re-fetch messages from the server since this assumes both user and assistant messages
      // were created synchronously on the server. Then update them in the `messages` cache.
      const messages = await apiClient.query(api.messages.getAllByThreadId, {
        threadId,
        deviceId,
      });

      // Update the `messages` cache
      setMessages(messages);

      // get answer for this message
      const lastMessage = messages[0];
      if (lastMessage.role !== "assistant") {
        throw new Error("Expected an assistant message");
      }
      setSubscribedMessageId(lastMessage.id);
    },
    [createMessage, createThread, router, threadId, ref],
  );

  const updating = messages[0]?.id === "mock" || !!subscribedMessageId;

  return (
    <Chat
      messages={messages}
      onSend={handleSend}
      disabled={updating}
      placeholder={
        // we want to deviceId is set because that indicates the page is rendered on the client-side
        // we don't want to render this text in html
        deviceId &&
        !threadId && (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Heading>Bạn có câu hỏi gì về sức khỏe?</Heading>
          </View>
        )
      }
    />
  );
}

function userMessageMock(text: string, files?: FileTemp[]): MessageDoc {
  return {
    id: "mock",
    threadId: "mock",
    text,
    files: files || [],
    userId: "mock",
    deviceId: "mock",
    role: "user" as const,
  };
}

function assistantMessageMock(): MessageDoc {
  return {
    id: "mock",
    threadId: "mock",
    files: [],
    text: "",
    replyMessageId: "",
    deviceId: "mock",
    refusal: null,
    finishReason: null,
    role: "assistant",
  };
}
