const palette = {
  black: "rgba(0,0,0,1)",
  white: "rgba(255,255,255,1)",

  zinc: {
    50: "rgba(250,250,250,1)",
    100: "rgba(244,244,245,1)",
    200: "rgba(228,228,231,1)",
    300: "rgba(212,212,216,1)",
    400: "rgba(161,161,170,1)",
    500: "rgba(113,113,122,1)",
    600: "rgba(82,82,91,1)",
    700: "rgba(63,63,70,1)",
    800: "rgba(39,39,42,1)",
    900: "rgba(24,24,27,1)",
    950: "rgba(9,9,11,1)",
  },
  neutral: {
    50: "rgba(250,250,250,1)",
    100: "rgba(245,245,245,1)",
    200: "rgba(229,229,229,1)",
    300: "rgba(212,212,212,1)",
    400: "rgba(163,163,163,1)",
    500: "rgba(115,115,115,1)",
    600: "rgba(82,82,82,1)",
    700: "rgba(64,64,64,1)",
    800: "rgba(38,38,38,1)",
    900: "rgba(23,23,23,1)",
    950: "rgba(10,10,10,1)",
  },
  red: {
    50: "rgba(254,242,242,1)",
    100: "rgba(254,226,226,1)",
    200: "rgba(254,202,202,1)",
    300: "rgba(252,165,165,1)",
    400: "rgba(248,113,113,1)",
    500: "rgba(239,68,68,1)",
    600: "rgba(220,38,38,1)",
    700: "rgba(185,28,28,1)",
    800: "rgba(153,27,27,1)",
    900: "rgba(127,29,29,1)",
    950: "rgba(69,10,10,1)",
  },
  orange: {
    50: "rgba(255,247,237,1)",
    100: "rgba(255,237,213,1)",
    200: "rgba(254,215,170,1)",
    300: "rgba(253,186,116,1)",
    400: "rgba(251,146,60,1)",
    500: "rgba(249,115,22,1)",
    600: "rgba(234,88,12,1)",
    700: "rgba(194,65,12,1)",
    800: "rgba(154,52,18,1)",
    900: "rgba(124,45,18,1)",
    950: "rgba(67,20,7,1)",
  },
  green: {
    50: "rgba(240,253,244,1)",
    100: "rgba(220,252,231,1)",
    200: "rgba(187,247,208,1)",
    300: "rgba(134,239,172,1)",
    400: "rgba(74,222,128,1)",
    500: "rgba(34,197,94,1)",
    600: "rgba(22,163,74,1)",
    700: "rgba(21,128,61,1)",
    800: "rgba(22,101,52,1)",
    900: "rgba(20,83,45,1)",
    950: "#052e16",
  },
  // made from https://uicolors.app/create from #80e2c5
  bermuda: {
    50: "#f1fcf7",
    100: "#d0f7ea",
    200: "#a2edd5",
    300: "#80e2c5",
    400: "#3cc5a1",
    500: "#23a987",
    600: "#19886f",
    700: "#186d5a",
    800: "#18574a",
    900: "#18493e",
    950: "#082b25",
  },
  // made from https://uicolors.app/create from #ff7962
  bittersweet: {
    50: "#fff3f1",
    100: "#ffe5e1",
    200: "#ffcfc7",
    300: "#ffaea0",
    400: "#ff7962",
    500: "#f8573b",
    600: "#e53a1d",
    700: "#c12d14",
    800: "#a02914",
    900: "#842818",
    950: "#481107",
  },
};

const lightColors = {
  text: {
    default: palette.zinc[800],
    primary: palette.bermuda[700],
    inverted: palette.zinc[50],
    muted: palette.zinc[600],
    mutedExtra: palette.zinc[500],
    destructive: palette.red[600],
  },

  input: {
    text: palette.zinc[800],
    background: palette.zinc[100],
    border: palette.zinc[900],
  },

  border: {
    default: palette.zinc[300],
    subtle: palette.zinc[100],
    selected: palette.bermuda[700],
  },

  background: {
    default: palette.white,
    muted: palette.zinc[50],
    mutedExtra: palette.zinc[100],
    backdrop: "rgba(0, 0, 0, 0.5)",
    backdropMuted: "rgba(10, 10, 10, 0.8)",
    primary: palette.bermuda[100],
  },

  button: {
    disabled: {
      color: palette.zinc[200],
      text: palette.white,
    },
    primary: {
      color: palette.bermuda[500],
      text: palette.white,
    },
    default: {
      color: palette.zinc[800],
      text: palette.white,
    },
    destructive: {
      color: palette.bittersweet[700],
      text: palette.white,
    },
  },

  status: {
    success: {
      background: palette.green[50],
    },
    warning: {
      background: palette.orange[50],
    },
    error: {
      background: palette.red[50],
    },
  },

  tooltip: {
    backgroundColor: palette.zinc[950],
    text: palette.white,
  },

  shadow: {
    color: palette.zinc[300],
    opacity: 0.7,
    radius: 14,
    offset: {
      width: 0,
      height: 8,
    },
  },
};

// TODO: add dark mode colors
export type Colors = typeof lightColors;
export const colors = lightColors;

export function useColors() {
  return colors;
}
