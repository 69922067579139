import IconEdit from "@tabler/icons-react-native/dist/esm/icons/IconEdit.mjs";
import IconThumbUp from "@tabler/icons-react-native/dist/esm/icons/IconThumbUp.mjs";
import IconLayoutSidebarLeftCollapse from "@tabler/icons-react-native/dist/esm/icons/IconLayoutSidebarLeftCollapse.mjs";
import IconLayoutSidebarLeftExpand from "@tabler/icons-react-native/dist/esm/icons/IconLayoutSidebarLeftExpand.mjs";
import IconThumbUpFilled from "@tabler/icons-react-native/dist/esm/icons/IconThumbUpFilled.mjs";
import IconThumbDown from "@tabler/icons-react-native/dist/esm/icons/IconThumbDown.mjs";
import IconThumbDownFilled from "@tabler/icons-react-native/dist/esm/icons/IconThumbDownFilled.mjs";
import IconArrowLeft from "@tabler/icons-react-native/dist/esm/icons/IconArrowLeft.mjs";
import IconArrowRight from "@tabler/icons-react-native/dist/esm/icons/IconArrowRight.mjs";
import IconArrowDown from "@tabler/icons-react-native/dist/esm/icons/IconArrowDown.mjs";
import IconArrowUp from "@tabler/icons-react-native/dist/esm/icons/IconArrowUp.mjs";
import IconX from "@tabler/icons-react-native/dist/esm/icons/IconX.mjs";
import IconCamera from "@tabler/icons-react-native/dist/esm/icons/IconCamera.mjs";
import IconPhoto from "@tabler/icons-react-native/dist/esm/icons/IconPhoto.mjs";
import IconPlus from "@tabler/icons-react-native/dist/esm/icons/IconPlus.mjs";
import { colors } from "./colors";

export type IconName =
  | "edit"
  | "layout-sidebar-left-collapse"
  | "layout-sidebar-left-expand"
  | "thumbs-up"
  | "thumbs-up-filled"
  | "thumbs-down"
  | "thumbs-down-filled"
  | "arrow-up"
  | "arrow-down"
  | "arrow-left"
  | "arrow-right"
  | "camera"
  | "photo"
  | "x"
  | "plus";

interface IconProps {
  name: IconName;
  size?: number;
  color?: string;
}

export function Icon(props: IconProps) {
  const { name, size = 24, color = colors.text.default } = props;

  switch (name) {
    case "edit":
      return <IconEdit size={size} color={color} />;
    case "layout-sidebar-left-collapse":
      return <IconLayoutSidebarLeftCollapse size={size} color={color} />;
    case "layout-sidebar-left-expand":
      return <IconLayoutSidebarLeftExpand size={size} color={color} />;
    case "arrow-up":
      return <IconArrowUp size={size} color={color} />;
    case "arrow-down":
      return <IconArrowDown size={size} color={color} />;
    case "arrow-left":
      return <IconArrowLeft size={size} color={color} />;
    case "arrow-right":
      return <IconArrowRight size={size} color={color} />;
    case "x":
      return <IconX size={size} color={color} />;
    case "plus":
      return <IconPlus size={size} color={color} />;
    case "camera":
      return <IconCamera size={size} color={color} />;
    case "photo":
      return <IconPhoto size={size} color={color} />;
    case "thumbs-up":
      return <IconThumbUp size={size} color={color} />;
    case "thumbs-down":
      return <IconThumbDown size={size} color={color} />;

    case "thumbs-up-filled":
      return (
        <IconThumbUpFilled
          fill={color}
          strokeWidth={0}
          size={size}
          color={color}
        />
      );
    case "thumbs-down-filled":
      return (
        <IconThumbDownFilled
          fill={color}
          strokeWidth={0}
          size={size}
          color={color}
        />
      );

    default:
      throw new Error(`Icon ${name} not found`);
  }
}
