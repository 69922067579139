import { Container } from "@/components/container";
import { ScrollView, View } from "react-native";
import { ArticleTemp } from "./types";
import { Text } from "@/components/text";
import { colors } from "@/components/colors";
import { ArticleView } from "@/components/articles/article_view";

interface PreviewProps {
  article: ArticleTemp;
}

export function Preview(props: PreviewProps) {
  const { article } = props;

  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      <ScrollView style={{ flex: 1 }}>
        <Container>
          <ArticleView article={article} />
        </Container>
      </ScrollView>
      <View
        style={{
          width: 400,
          padding: 16,
          borderLeftWidth: 1,
          borderColor: colors.border.default,
        }}
      >
        <Text>
          <Text bold>Slug: </Text>
          {article.slug}
        </Text>
        <Text>
          <Text bold>Title: </Text>
          {article.title}
        </Text>
        <Text>
          <Text bold>Description: </Text>
          {article.description}
        </Text>
      </View>
    </View>
  );
}
