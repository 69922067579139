import { Section } from "../section";
import { Container } from "../container";
import { ButtonCTA } from "../button_cta";
import { useRouter } from "expo-router";
import { useCallback } from "react";
import { analytics } from "@/helpers/analytics";

interface StartChatSectionProps {}

export function StartChatSection(props: StartChatSectionProps) {
  const router = useRouter();

  const handlePress = useCallback(() => {
    analytics.track("Start Chat Button Pressed", {
      From: "Start Chat Section",
    });
    router.push("/c");
  }, [router]);

  return (
    <Section
      title="Bạn cần tư vấn sức khỏe?"
      description="Gửi câu hỏi để nhận thông tin chính xác về triệu chứng và khám phá các giải pháp phù hợp cho sức khỏe của bạn."
    >
      <Container style={{ alignItems: "center" }}>
        <ButtonCTA
          color="primary"
          onPress={handlePress}
          title="Trò Chuyện Ngay!"
          shape="pill"
        />
      </Container>
    </Section>
  );
}
