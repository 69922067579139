import { Bots } from "ua-parser-js/src/extensions/ua-parser-extensions";
import { UAParser } from "ua-parser-js";
import { Platform } from "react-native";

export function detectBot() {
  if (Platform.OS !== "web") {
    return;
  }

  const userAgent = navigator.userAgent;

  const botParser = new UAParser(Bots); // includes CLIs, Crawlers, Fetchers, Modules
  const botBrowser = botParser.setUA(userAgent).getBrowser();

  if (
    botBrowser.type === "crawler" ||
    botBrowser.type === "fetcher" ||
    botBrowser.type === "cli" ||
    botBrowser.type === "mediaplayer"
  ) {
    return {
      name: botBrowser.name,
      type: botBrowser.type,
    };
  }
}
