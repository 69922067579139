import { ScrollView, StyleSheet, View } from "react-native";
import { Icon } from "../icon";
import { colors } from "../colors";
import { FileTemp } from "./types";
import { PressableHighlight } from "../pressable_highlight";
import { Image } from "expo-image";
import { i18n } from "@/helpers/i18n";
import { FileDoc } from "@/server/db/messages";

export interface ComposerFilesProps {
  files: (FileTemp | FileDoc)[];
  onRemoveFile?: (fileUri: string) => void;
}

export function ComposerFiles(props: ComposerFilesProps) {
  const { files, onRemoveFile } = props;

  return (
    <>
      <ScrollView
        horizontal
        style={styles.container}
        contentContainerStyle={{ gap: 16 }}
      >
        {files.map((file) => {
          let content = null;

          if (file.type === "image") {
            content = <Image style={styles.file} source={{ uri: file.url }} />;
          }

          return (
            <View key={file.name} style={styles.file}>
              {content}
              {onRemoveFile && (
                <View style={styles.xWrapper}>
                  <PressableHighlight
                    backgroundColor={colors.background.default}
                    onPress={() => onRemoveFile(file.url)}
                    style={styles.x}
                    tooltip={i18n.t("chat.removeFile")}
                    tooltipPosition="right"
                  >
                    <Icon size={16} name="x" color={colors.text.default} />
                  </PressableHighlight>
                </View>
              )}
            </View>
          );
        })}
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 12, // enough to show tooltip
    paddingBottom: 8,
  },
  xWrapper: {
    position: "absolute",
    top: -8,
    right: -8,
  },
  x: {
    height: 24,
    width: 24,
    borderRadius: 999,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: colors.border.default,
  },
  file: {
    height: 128,
    width: 128,
    borderRadius: 8,
  },
});
