import React from "react";
import {
  ColorValue,
  Text as RNText,
  TextProps as RNTextProps,
  StyleSheet,
} from "react-native";
import { colors } from "./colors";

export interface TextProps {
  children?: RNTextProps["children"];
  lineHeight?: number;
  size?: number | "sm" | "md" | "lg" | "xl";
  color?: ColorValue;
  align?: TextAlign;
  testID?: string;
  italic?: boolean;
  bold?: boolean;
  numberOfLines?: number;
  userSelect?: "none" | "auto";
  transform?: "uppercase" | "lowercase" | "capitalize";
  decoration?: TextDecorationLine;
}

export type TextAlign = "left" | "right" | "center";

type TextDecorationLine =
  | "none"
  | "underline"
  | "lineThrough"
  | "underline lineThrough";

export type TextWeight = "400" | "500" | "600";

/**
 * Run of text with a single style.
 */
export function Text(props: TextProps): JSX.Element {
  const {
    userSelect = "auto",
    align = "left",
    children,
    color = colors.text.default,
    lineHeight = 24,
    size = 16,
    testID,
    numberOfLines,
    decoration = "none",
    bold,
    italic,
    transform = "none",
  } = props;

  return (
    <RNText
      testID={testID}
      style={[
        {
          lineHeight,
          fontStyle: italic ? "italic" : "normal",
          color,
        },
        typeof size === "number" && { fontSize: size },
        typeof size === "string" && sizeStyles[size],
        bold && textWeightStyles["600"],
        textStyle.text,
        userSelectStyles[userSelect],
        textAlignStyles[align],
        textDecorationStyles[decoration],
        transformStyles[transform],
      ]}
      numberOfLines={numberOfLines}
    >
      {children}
    </RNText>
  );
}

const sizeStyles = StyleSheet.create({
  sm: {
    fontSize: 14,
  },
  md: {
    fontSize: 16,
  },
  lg: {
    fontSize: 20,
  },
  xl: {
    fontSize: 28,
  },
});

const textStyle = StyleSheet.create({
  text: {},
});

const textAlignStyles = StyleSheet.create({
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  center: {
    textAlign: "center",
  },
});

const textWeightStyles = StyleSheet.create({
  "400": {
    fontWeight: 400,
  },
  "600": {
    fontWeight: 600,
  },
});

const textDecorationStyles = StyleSheet.create({
  none: {
    textDecorationLine: "none",
  },
  underline: {
    textDecorationLine: "underline",
  },
  lineThrough: {
    textDecorationLine: "line-through",
  },
  "underline lineThrough": {
    textDecorationLine: "underline line-through",
  },
});

const userSelectStyles = StyleSheet.create({
  auto: {
    userSelect: "auto",
  },
  none: {
    userSelect: "none",
  },
});

const transformStyles = StyleSheet.create({
  none: {
    textTransform: "none",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  lowercase: {
    textTransform: "lowercase",
  },
  capitalize: {
    textTransform: "capitalize",
  },
});
