import { ArticleView } from "@/components/articles/article_view";
import { StartChatSection } from "@/components/articles/start_chat_section";
import { Container } from "@/components/container";
import { useQuery } from "@/helpers/api";
import { env } from "@/helpers/env";
import { api } from "@/server/api";
import { ArticleDoc } from "@/server/db/articles";
import { useLocalSearchParams } from "expo-router";
import Head from "expo-router/head";
import { View } from "react-native";

type ArticlePageParams = {
  slug: string;
};

export async function generateStaticParams() {
  const response = await fetch(`${env.serverUrl}/articles/getAll`);
  const articles = (await response.json()) as ArticleDoc[];

  return articles.map((article) => ({ slug: article.slug }));
}

export default function ArticlePage() {
  const { slug } = useLocalSearchParams<ArticlePageParams>();
  const article = useQuery(api.articles.getBySlug, { slug });

  if (!article) return null;

  return (
    <>
      <Head>
        <title>{article.title}</title>
        <meta name="description" content={article.description} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://ylan.vn/suc-khoe/${article.slug}`}
        />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.description} />
        <meta property="og:image" content={article.imageUrl} />
        <link
          rel="canonical"
          href={`https://ylan.vn/suc-khoe/${article.slug}`}
        />
      </Head>
      <Container>
        <ArticleView article={article} />
      </Container>
      <View style={{ height: 40 }} />
      <StartChatSection />
    </>
  );
}
