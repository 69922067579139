import { AboveFoldSection } from "@/components/home_page/above_fold_section";
import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import { HighlightedBenefitsSection } from "@/components/home_page/highlighted_benefits_section";
import { KeyFeaturesSection } from "@/components/home_page/key_features_section";
import { StartChatSection } from "@/components/home_page/start_chat_section";
import { HowItWorksSection } from "@/components/home_page/how_it_works_section";

export default function HomePage() {
  return (
    <>
      <Header transparentThreshold />
      <AboveFoldSection />
      <main>
        <HowItWorksSection />
        <KeyFeaturesSection />
        <HighlightedBenefitsSection />
      </main>
      <StartChatSection />
      <Footer />
    </>
  );
}
