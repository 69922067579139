import Head from "expo-router/head";
import "@/components/variables.css";
import "@/components/global.css";

import * as SplashScreen from "expo-splash-screen";
import { Slot, usePathname } from "expo-router";
import { useEffect } from "react";
import { analytics } from "@/helpers/analytics";
import { detectBot } from "@/helpers/detect_bot";
import { ToastProvider } from "@/components/toast";

SplashScreen.preventAutoHideAsync();

function RootLayout() {
  return (
    <>
      <Head>
        <title>YLan | Trợ Lý Sức Khỏe Trực Tuyến Đáng Tin Cậy</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="description"
          content="YLan là trợ lý sức khỏe trực tuyến giúp bạn kiểm tra triệu chứng, tìm hiểu về thuốc và phương pháp điều trị, nhận lời khuyên sức khỏe cá nhân và tìm cơ sở y tế gần bạn. Bảo mật, đáng tin cậy và hoàn toàn miễn phí."
        />
        <meta
          name="keywords"
          content="YLan, trợ lý sức khỏe trực tuyến, kiểm tra triệu chứng, tư vấn y tế, lời khuyên sức khỏe cá nhân, cơ sở y tế gần tôi, thông tin thuốc, chăm sóc phòng ngừa, công cụ sức khỏe miễn phí, bảo mật, đáng tin cậy"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ylan.vn" />
        <meta
          property="og:title"
          content="YLan | Trợ Lý Sức Khỏe Trực Tuyến Đáng Tin Cậy"
        />
        <meta
          property="og:description"
          content="YLan giúp bạn kiểm tra triệu chứng, tìm hiểu về thuốc và phương pháp điều trị, nhận lời khuyên sức khỏe cá nhân và tìm cơ sở y tế gần bạn. Bảo mật, đáng tin cậy và hoàn toàn miễn phí."
        />
        <meta
          property="og:image"
          content="https://ylan.vn/images/social-preview-1.jpg"
        />
      </Head>
      <ToastProvider>
        <App>
          <Slot />
        </App>
      </ToastProvider>
    </>
  );
}

interface AppLoaderProps {
  children: React.ReactNode;
}

function App(props: AppLoaderProps) {
  const { children } = props;
  const pathname = usePathname();

  useEffect(() => {
    const bot = detectBot();

    if (bot) {
      analytics.track("Bot Detected", {
        Bot: bot.name,
        Type: bot.type,
      });
      return;
    }

    analytics.register({ Path: pathname });
    analytics.track("Page View");
  }, [pathname]);

  useEffect(() => {
    SplashScreen.hideAsync();
  }, []);

  return <>{children}</>;
}

export default RootLayout;
