import { ArticleCard } from "@/components/articles/article_card";
import { Grid } from "@/components/grid";
import { useScreenSize } from "@/hooks/use_screen_size";
import { ArticleDoc } from "@/server/db/articles";
import { Link } from "expo-router";

interface ArticleListProps {
  articles: ArticleDoc[];
}

export function ArticleList(props: ArticleListProps) {
  const { articles } = props;
  const screenSize = useScreenSize();

  return (
    <Grid
      keyExtractor={(article) => article.id}
      data={articles}
      minColumns={screenSize.small ? 1 : 2}
      gap={16}
      renderItem={({ item: article }) => (
        <Link key={article.id} href={`/suc-khoe/${article.slug}`}>
          <ArticleCard article={article} />
        </Link>
      )}
    />
  );
}
