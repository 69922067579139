import {
  Animated,
  View,
  TextInput as RNTextInput,
  StyleSheet,
} from "react-native";
import * as ImagePicker from "expo-image-picker";
import * as DocumentPicker from "expo-document-picker";

import { SendButton } from "./send_button";
import { AttachFileButton } from "./attach_file_button";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ComposerFiles } from "./composer_files";
import { FileTemp, FileImage, FilePDF } from "./types";
import { TextInput } from "./text_input";
import { colors } from "../colors";
import { useScreenSize } from "@/hooks/use_screen_size";

export interface ComposerProps {
  text: string;
  files: FileTemp[];
  sendOnEnter?: boolean;

  onChangeText: (text: string) => void;
  onAddFiles: (files: FileTemp[]) => void;
  onRemoveFile: (fileUri: string) => void;
  onSend: () => void;

  disabled?: boolean;
}

export interface ComposerRef {
  focus: () => void;
}

export const Composer = forwardRef<ComposerRef, ComposerProps>(
  function Composer(props, ref) {
    const {
      text,
      files,
      sendOnEnter,
      onChangeText,
      onAddFiles,
      onRemoveFile,
      onSend,
      disabled,
    } = props;
    const textInputRef = useRef<RNTextInput>(null);
    const [focused, setFocused] = useState(false);
    const screenSize = useScreenSize();
    const emptyText = text.trim() === "";

    useImperativeHandle(ref, () => {
      return {
        focus: () => {
          textInputRef.current?.focus();
        },
      };
    });

    const handleSend = useCallback(() => {
      if (emptyText || disabled) {
        return;
      }

      onSend();

      if (screenSize.small) {
        // Hide keyboard on mobile after sending message
        textInputRef.current?.blur();
        return;
      }

      // On desktop, keep focus on the input after sending message
      textInputRef.current?.focus();
    }, [onSend, emptyText, screenSize, disabled]);

    const handleAddFileImagesFromCamera = useCallback(async () => {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
      });

      if (result.canceled) {
        return;
      }

      const images: FileImage[] = result.assets.map((asset) => ({
        type: "image",
        name: asset.fileName || "unkown",
        mimeType: asset.mimeType,
        size: asset.fileSize,
        url: asset.uri,
        uploaded: false,
        width: asset.width,
        height: asset.height,
      }));

      onAddFiles(images);
    }, [onAddFiles]);

    const handleAddFileImagesFromGallery = useCallback(async () => {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsMultipleSelection: true,
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
      });

      if (result.canceled) {
        return;
      }

      const images: FileImage[] = result.assets.map((asset) => ({
        type: "image",
        name: asset.fileName || "unkown",
        mimeType: asset.mimeType,
        size: asset.fileSize,
        url: asset.uri,
        uploaded: false,
        width: asset.width,
        height: asset.height,
      }));

      onAddFiles(images);
    }, [onAddFiles]);

    const handleAddFilePDF = useCallback(async () => {
      const result = await DocumentPicker.getDocumentAsync({
        type: ["application/pdf"],
      });

      if (result.canceled) {
        return;
      }

      const pdf: FilePDF[] = result.assets.map((asset) => ({
        type: "pdf",
        name: asset.name,
        mimeType: asset.mimeType,
        size: asset.size,
        url: asset.uri,
        uploaded: false,
      }));

      onAddFiles(pdf);
    }, [onAddFiles]);

    return (
      <Animated.View
        style={[
          styles.container,
          {
            borderColor: focused ? colors.input.border : colors.border.subtle,
          },
        ]}
      >
        <View style={styles.attachFileButtonContainer}>
          <AttachFileButton
            onAddFileImagesFromCamera={handleAddFileImagesFromCamera}
            onAddFileImagesFromGallery={handleAddFileImagesFromGallery}
            onAddFilePDF={handleAddFilePDF}
          />
        </View>
        <View style={styles.inputContainer}>
          {files.length > 0 && (
            <ComposerFiles files={files} onRemoveFile={onRemoveFile} />
          )}
          <TextInput
            onChangeText={onChangeText}
            value={text}
            textInputRef={textInputRef}
            onSubmitEditing={handleSend}
            sendOnEnter={sendOnEnter}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
        </View>
        {!emptyText && (
          <View style={styles.sendButtonContainer}>
            <SendButton disabled={disabled} onPress={handleSend} />
          </View>
        )}
      </Animated.View>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    borderRadius: 24,
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: colors.input.background,
    flexDirection: "row",
    borderWidth: 2,

    shadowOffset: colors.shadow.offset,
    shadowOpacity: colors.shadow.opacity,
    shadowRadius: colors.shadow.radius,
    shadowColor: colors.shadow.color,
  },
  attachFileButtonContainer: {
    justifyContent: "flex-end",
    paddingRight: 8,
    zIndex: 1,
  },
  sendButtonContainer: {
    justifyContent: "flex-end",
    paddingLeft: 8,
    zIndex: 1,
  },
  inputContainer: {
    flex: 1,
    gap: 8,
    justifyContent: "center",
  },
});
