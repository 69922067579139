import { Link, useRouter } from "expo-router";
import styles from "./header.module.css";

import React, { useState, useEffect, useCallback } from "react";

import { Logo } from "@/components/logo";
import { ButtonCTA } from "@/components/button_cta";
import { analytics } from "@/helpers/analytics";

interface HeaderProps {
  /* The header will be tranparent above a threshold */
  transparentThreshold?: boolean;
}

export function Header(props: HeaderProps) {
  const { transparentThreshold } = props;
  const [transparent, setTransparent] = useState(transparentThreshold);
  const router = useRouter();

  useEffect(() => {
    if (!transparentThreshold) {
      return;
    }

    const handleScroll = () => {
      if (window.scrollY < 300) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [transparentThreshold]);

  const handlePress = useCallback(() => {
    analytics.track("Start Chat Button Pressed", { From: "Header" });
    router.push("/c");
  }, [router]);

  return (
    <div
      className={`${styles.container} ${transparent ? styles.transparent : ""}`}
    >
      <div className={styles.containerInner}>
        <Link className={styles.logoLink} href="/">
          <Logo />
        </Link>
        <ButtonCTA
          onPress={handlePress}
          color="primary"
          title="Nhận Tư Vấn Sức Khỏe"
        />
      </div>
    </div>
  );
}
