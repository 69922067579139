import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState, useEffect } from "react";
import { uuid } from "./uuid";

const key = "__ylan:deviceId";

let mutex = false;

// When first call is made, `mutex` is false so it will proceed to find existing deviceId or generate a new device ID.
// If there's another call made before the first call is finished, it will wait until the first call is finished.
// This is to prevent multiple calls to generate a new device ID.
export async function findOrCreateDeviceId() {
  while (mutex) {
    await new Promise((resolve) => setTimeout(resolve, 0));
  }

  mutex = true;
  let deviceId = await AsyncStorage.getItem(key);

  if (!deviceId) {
    deviceId = uuid();

    await AsyncStorage.setItem(key, deviceId);
  }

  mutex = false;
  return deviceId;
}

export function useDeviceId() {
  const [deviceId, setDeviceId] = useState<string | null>(null);

  useEffect(() => {
    findOrCreateDeviceId().then(setDeviceId);
  }, []);

  return deviceId;
}
