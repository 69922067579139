import React, { useCallback, useMemo, useState } from "react";
import { LayoutChangeEvent, ScrollView, View } from "react-native";

interface GridRenderItem<T = any> {
  index: number;
  item: T;
  width: number;
}

interface GridProps<T = any> {
  data: T[];
  renderItem: (info: GridRenderItem<T>) => React.ReactNode;
  gap?: number;
  minColumns?: number;
  /** adds "invisible" padding to the content so that the container does not cutoff content e.g. shadows and borders */
  offset?: number;
  keyExtractor?: ((item: T, index: number) => string) | undefined;
  minItemWidth?: number;
}

/**
 * Grid is a component that renders a list of items in a grid layout
 * where the number of columns is determined by the width of the container.
 */
export function Grid<T = any>(props: GridProps<T>) {
  const {
    data,
    gap = 0,
    minColumns = 2,
    offset = 0,
    keyExtractor,
    renderItem,
    minItemWidth,
  } = props;
  const [size, setSize] = useState({ width: 0, height: 0 });

  const numColumns = minItemWidth
    ? Math.max(minColumns, Math.floor(size.width / minItemWidth))
    : minColumns;

  const itemWidth = useMemo(
    () => (size.width - offset - gap * (numColumns - 1)) / numColumns,
    [size, offset, gap, numColumns],
  );

  const handleLayout = useCallback(
    (e: LayoutChangeEvent) => {
      setSize({
        width: e.nativeEvent.layout.width - offset * 2,
        height: e.nativeEvent.layout.height - offset * 2,
      });
    },
    [offset],
  );

  return (
    <ScrollView
      onLayout={handleLayout}
      contentContainerStyle={{
        flexWrap: "wrap",
        flexDirection: "row",
        gap,
      }}
      style={{
        margin: -offset,
        padding: offset,
      }}
    >
      {data.map((item, index) => (
        <View key={keyExtractor?.(item, index)} style={{ width: itemWidth }}>
          {renderItem({ item, index, width: itemWidth })}
        </View>
      ))}
    </ScrollView>
  );
}
