import { StyleSheet, View, ViewStyle } from "react-native";
import { Icon, IconName } from "../icon";
import { Text } from "../text";
import { colors } from "../colors";
import { PressableHighlight } from "../pressable_highlight";

export interface FileMenuProps {
  onAddFileImagesFromCamera: () => void;
  onAddFileImagesFromGallery: () => void;
  onAddFilePDF: () => void;
}

export function FileMenu(props: FileMenuProps) {
  const { onAddFileImagesFromCamera, onAddFileImagesFromGallery } = props;

  return (
    <View style={styles.container}>
      <FileMenuItem
        onPress={onAddFileImagesFromCamera}
        iconName="camera"
        label="Camera"
        style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
      />
      <FileMenuItem
        onPress={onAddFileImagesFromGallery}
        iconName="photo"
        label="Gallery"
        style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
      />
    </View>
  );
}

interface FileMenuItemProps {
  onPress: () => void;
  iconName: IconName;
  label: string;
  style?: ViewStyle;
}

function FileMenuItem(props: FileMenuItemProps) {
  const { onPress, iconName, label, style } = props;

  return (
    <PressableHighlight style={[styles.fileMenuItem, style]} onPress={onPress}>
      <Icon size={24} name={iconName} />
      <Text>{label}</Text>
    </PressableHighlight>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.border.default,
    borderRadius: 8,
    backgroundColor: colors.background.default,
  },
  fileMenuItem: {
    alignItems: "center",
    flexDirection: "row",
    gap: 8,
    paddingVertical: 8,
    paddingHorizontal: 16,
    minWidth: 200,
    userSelect: "none",
  },
});
