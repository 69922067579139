import { useColors } from "@/components/colors";
import { StyleSheet } from "react-native";
import { Icon } from "../icon";
import { PressableHighlight } from "../pressable_highlight";
import { i18n } from "@/helpers/i18n";

interface SendButtonProps {
  onPress: () => void;
  disabled?: boolean;
}

export function SendButton(props: SendButtonProps) {
  const { onPress, disabled } = props;

  const colors = useColors();

  return (
    <PressableHighlight
      tooltip={i18n.t("chat.send")}
      disabled={disabled}
      disabledColor={colors.button.disabled.color}
      backgroundColor={colors.button.primary.color}
      onPress={onPress}
      style={styles.button}
    >
      <Icon size={24} color={colors.button.primary.text} name="arrow-up" />
    </PressableHighlight>
  );
}

const styles = StyleSheet.create({
  button: {
    userSelect: "none",
    borderRadius: 999,
    flexDirection: "row",
    width: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
  },
});
