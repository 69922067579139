import { Reducer, useCallback, useMemo, useReducer } from "react";

export interface UseFormProps<T> {
  initialValues: T;
  validate?: (
    values: T,
  ) => FormErrors<T> | Promise<FormErrors<T>> | undefined | Promise<undefined>;
  onSubmit: (values: T, helpers: FormSetters<T>) => Promise<void> | void;
}

export interface UseFormData<T> extends FormSetters<T> {
  submit: () => Promise<void>;
  reset: () => void;
  validate: () => void;
  values: T;
  status: string;
  errors: FormErrors<T>;
  changed: FormChanged<T>;
  submitting: boolean;
  submitCount: number;
}

export interface FormSetters<T> {
  setFieldError: (field: keyof T, message?: string) => void;
  setFieldValue: (field: keyof T) => (value: T[keyof T]) => void;
  setFieldValues: (values: Partial<T>) => void;
  setStatus: (status: string) => void;
  setSubmitting: (submitting: boolean) => void;
  setErrors: (errors: FormErrors<T>) => void;
}

interface FormState<T> {
  values: T;
  errors: FormErrors<T>;
  changed: FormChanged<T>;
  status: string;
  submitting: boolean;
  submitCount: number;
}

export type FormErrors<T> = { [key in keyof T]?: string };
export type FormChanged<T> = { [key in keyof T]?: boolean };

type Action<T> =
  | {
      type: "SET_FIELD_VALUE";
      field: keyof T;
      value: T[keyof T];
    }
  | {
      type: "SET_FIELD_VALUES";
      values: Partial<T>;
    }
  | {
      type: "SET_FIELD_ERROR";
      field: keyof T;
      message?: string;
    }
  | {
      type: "SET_ERRORS";
      errors: FormErrors<T>;
    }
  | {
      type: "SET_STATUS";
      status: string;
    }
  | {
      type: "SUBMIT";
    }
  | {
      type: "SET_SUBMITTING";
      submitting: boolean;
    }
  | {
      type: "RESET";
      initialValues: T;
    };

function reducer<T>(prevState: FormState<T>, action: Action<T>): FormState<T> {
  switch (action.type) {
    case "SET_FIELD_VALUE":
      delete prevState.errors[action.field];

      return {
        ...prevState,
        values: {
          ...prevState.values,
          [action.field]: action.value,
        },
        errors: {
          ...prevState.errors,
        },
        changed: {
          ...prevState.changed,
          [action.field]: true,
        },
      };
    case "SET_FIELD_VALUES":
      return {
        ...prevState,
        values: {
          ...prevState.values,
          ...action.values,
        },
        changed: {
          ...prevState.changed,
          ...action.values,
        },
      };
    case "SET_ERRORS":
      return {
        ...prevState,
        errors: {
          ...action.errors,
        },
      };
    case "SET_FIELD_ERROR":
      if (action.message === undefined) {
        delete prevState.errors[action.field];

        return {
          ...prevState,
          errors: {
            ...prevState.errors,
          },
        };
      }

      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          [action.field]: action.message,
        },
      };
    case "SET_STATUS":
      return {
        ...prevState,
        status: action.status,
      };
    case "SET_SUBMITTING":
      return {
        ...prevState,
        submitting: action.submitting,
      };
    case "SUBMIT":
      return {
        ...prevState,
        submitCount: prevState.submitCount + 1,
      };
    case "RESET":
      return {
        values: action.initialValues,
        errors: {},
        changed: {},
        status: "",
        submitting: false,
        submitCount: 0,
      };
    default:
      throw new Error("Action type not resolved");
  }
}

export function useForm<T>(props: UseFormProps<T>): UseFormData<T> {
  const { initialValues, validate, onSubmit } = props;
  const initialState = useMemo(
    (): FormState<T> => ({
      values: initialValues,
      errors: {},
      changed: {},
      status: "",
      submitting: false,
      submitCount: 0,
    }),
    [initialValues],
  );
  const [state, dispatch] = useReducer<Reducer<FormState<T>, Action<T>>>(
    reducer,
    initialState,
  );

  const { values, errors, changed, submitting, status, submitCount } = state;

  const setErrors = useCallback((nextErrors: FormErrors<T>) => {
    dispatch({ type: "SET_ERRORS", errors: nextErrors });
  }, []);

  const setFieldError = useCallback(
    (field: keyof T, message?: string): void => {
      dispatch({ type: "SET_FIELD_ERROR", field, message });
    },
    [],
  );

  const setStatus = useCallback((newStatus: string) => {
    dispatch({ type: "SET_STATUS", status: newStatus });
  }, []);

  const setSubmitting = useCallback((newSubmitting: boolean) => {
    dispatch({ type: "SET_SUBMITTING", submitting: newSubmitting });
  }, []);

  const setFieldValue = useCallback((field: keyof T) => {
    return (value: T[keyof T]) => {
      dispatch({ type: "SET_FIELD_VALUE", field, value });
    };
  }, []);

  const setFieldValues = useCallback((newValues: Partial<T>) => {
    dispatch({ type: "SET_FIELD_VALUES", values: newValues });
  }, []);

  const handleValidate = useCallback(async () => {
    if (validate !== undefined) {
      setErrors((await validate(values)) || {});
    }
  }, [validate, values, setErrors]);

  const formHelpers = useMemo(
    (): FormSetters<T> => ({
      setStatus,
      setFieldError,
      setFieldValue,
      setFieldValues,
      setErrors,
      setSubmitting,
    }),
    [
      setFieldValues,
      setStatus,
      setFieldError,
      setFieldValue,
      setErrors,
      setSubmitting,
    ],
  );

  const submit = useCallback(async () => {
    dispatch({ type: "SUBMIT" });
    if (validate !== undefined) {
      const nextErrors = await validate(values);

      if (nextErrors && Object.keys(nextErrors).length) {
        setErrors(nextErrors);
        return;
      }
    }

    dispatch({ type: "SET_SUBMITTING", submitting: true });
    await onSubmit(values, formHelpers);
    dispatch({ type: "SET_SUBMITTING", submitting: false });
  }, [onSubmit, validate, setErrors, values, formHelpers]);

  const reset = useCallback(() => {
    dispatch({ type: "RESET", initialValues });
  }, [initialValues]);

  return {
    setFieldValues,
    setFieldValue,
    setFieldError,
    setErrors,
    setSubmitting,
    setStatus,
    submit,
    reset,
    values,
    errors,
    changed,
    submitting,
    status,
    validate: handleValidate,
    submitCount,
  };
}
