import type * as articles from "./api/articles.js";
import type * as analytics from "./api/analytics.js";
import type * as messages from "./api/messages.js";
import type * as threads from "./api/threads.js";

type Api = {
  articles: ModuleApi<typeof articles>;
  analytics: ModuleApi<typeof analytics>;
  messages: ModuleApi<typeof messages>;
  threads: ModuleApi<typeof threads>;
};

export const api: Api = createApi() as any;

function createApi(pathParts: string[] = []) {
  const handler: ProxyHandler<{ path: string }> = {
    get(target, prop: string | symbol) {
      if (typeof prop === "string") {
        const newParts = [...pathParts, prop];

        if (newParts.length > 1) {
          return { path: `/${newParts.join("/")}` };
        }

        return createApi(newParts);
      } else if (typeof prop === "symbol") {
        return target.path;
      }

      return target;
    },
  };

  return new Proxy({ path: `/${pathParts.join("/")}` }, handler);
}

type ModuleApi<T extends Record<string, any>> = {
  [K in Extract<keyof T, string>]: T[K] extends {
    type: infer M;
    scope: "public";
    _args: infer A;
    _result: infer R;
  }
    ? {
        path: `/${K}`;
        type: M;
        _args: A;
        _result: R;
      }
    : never;
};
