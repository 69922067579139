body {
  -webkit-font-smoothing: antialiased; /* For Safari */
  -moz-osx-font-smoothing: grayscale; /* For Firefox on Mac */
  text-rendering: optimizeLegibility;
  font-family:
    ui-sans-serif,
    -apple-system,
    system-ui,
    Segoe UI,
    Helvetica,
    Apple Color Emoji,
    Arial,
    sans-serif,
    Segoe UI Emoji,
    Segoe UI Symbol;
  font-variation-settings: normal;
}
